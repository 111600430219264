import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from "./history";

import "./App.css";
import Navigation from "./containers/Navigation/Navigation";
import PlayerProfile from "./containers/Players/PlayerProfile";
import GameDetails from "./containers/Game/GameDetails";
import Home from "./containers/Home/Home";
import TeamProfile from "./containers/Team/TeamProfile";
import TeamsList from "./containers/Teams/TeamsList";
import Statistics from "./containers/Statistics/Statistics";
import Playoff from "./containers/Playoff/Playoff";
import TopGamePerformance from "./containers/TopGamePerformance/TopGamePerformance";
import VotingPage from "./containers/Voting/VotingPage";
import SeasonAwards from "./containers/VotingSeasonAwards/SeasonAwards";
import VotingResultsPage from "./containers/Voting/VotingResultsPage";
import ErrorPage from "./containers/ErrorPage/ErrorPage";
import {ERROR_PAGE_404_PATHNAME} from "./constants/constants";

import Interceptor from "./api/AxiosInstance.interceptor";
import Footer from "./containers/Footer/Footer";
Interceptor.interceptor(history);

function App() {
  return (
    <div>
      <Router history={history}>
        <Navigation />
        <main>
          <Switch>
            <Route path="/players/:id" exact component={({match}) => (<PlayerProfile playerId={match.params.id} />)} />
            <Route path="/games/:id" exact component={({match}) => (<GameDetails gameId={match.params.id} />)} />
            <Route path="/teams/:id" exact component={({match}) => (<TeamProfile teamId={match.params.id} />)} />
            <Route path="/teams" exact component={() => (<TeamsList />)} />
            <Route path="/statistics" exact component={() => (<Statistics />)} />
            <Route path="/playoff" exact component={() => (<Playoff />)} />
            <Route path="/topPerformances" exact component={() => (<TopGamePerformance />)} />
            {/* <Route path="/allstar" exact component={() => (<VotingPage />)} /> */}
            {/* <Route path="/allstar" exact component={() => (<VotingResultsPage />)} /> */}
            <Route path="/season_awards" exact component={() => (<SeasonAwards />)} />
            <Route path={ERROR_PAGE_404_PATHNAME} exact component={ErrorPage} />
            <Route path="/" exact component={() => (<Home />)} />
            <Redirect to={ERROR_PAGE_404_PATHNAME} component={ErrorPage} />
          </Switch>
        </main>
        <Footer />
      </Router>
    </div>
  );  
}

export default App;
