import history from "../history";
import { IFNCS_STRING, LOWERCASE_KEYS, REGULAR_NUMBER_OF_PLAYOFF_TEAMS, RESCHEDULED_STRING, SHORT_NUMBER_OF_PLAYOFF_TEAMS, TBD_STRING, UPPERCASE_KEYS } from "./constants";

export const getQueryParams = () => {
  return new URLSearchParams(window.location.search);
};

export const getSeasonYearFromQuery = () => {
  const params = new URLSearchParams(window.location.search);
  const seasonYear = params.get("season");
  return seasonYear;
};

export const updateQuery = (updatedParams) => {
  const queryParams = new URLSearchParams(window.location.search);
  Object.keys(updatedParams).forEach((key) => {
    queryParams.set(key, updatedParams[key]);
  });
  history.push(`?${queryParams.toString()}`);
};

export const getEmptyStatsTotal = () => {
  let emptyStat = {}

  emptyStat.MP = 0;
  emptyStat.PTS = 0;
  emptyStat.DREB = 0;
  emptyStat.OREB = 0;
  emptyStat.AST = 0;
  emptyStat.STL = 0;
  emptyStat.BLK = 0;
  emptyStat.TWO_PM = 0;
  emptyStat.TWO_PA = 0;
  emptyStat.THREE_PM = 0;
  emptyStat.THREE_PA = 0;
  emptyStat.THREE_PM = 0;
  emptyStat.THREE_PA = 0;
  emptyStat.FTM = 0;
  emptyStat.FTA = 0;
  emptyStat.TOS = 0;

  return emptyStat
}

// IZRAČUNAJ UKUPNU STATISTIKU
export const getStatsTotal = (stats) => {
  
  if (stats.length === 0) return getEmptyStatsTotal();
  let statsTotal = {};

  stats.forEach((stat) => {
    Object.keys(stat).forEach((key) => {
      statsTotal[key] = (statsTotal[key] || 0) + stat[key];
    });
  });
  return statsTotal;
};

// IZRAČUNAJ PROSJEK STATISTIKE
export const getStatsAvg = (statsTotal, gamesPlayed) => {
  let statsAvg = {};

  Object.keys(statsTotal).forEach((key) => {
    statsAvg[key] = gamesPlayed === 0 ? statsTotal[key] : statsTotal[key] / gamesPlayed;
  });
  return statsAvg;
};

/**
 * Function that calculates and adds keys: REB, FGM, FGA, FG_PERCENTAGE, THREE_PERCENTAGE, FT_PERCENTAGE to stat object
 *
 * @param stat object containing stats fields
 */
export const calculateFgRebPercentage = (stat) => {
  stat.REB = stat.REB ? stat.REB : parseFloat(stat.DREB) + parseFloat(stat.OREB);
  stat.FGM = stat.FGM ? stat.FGM : parseFloat(stat.TWO_PM) + parseFloat(stat.THREE_PM);
  stat.FGA = stat.FGA ? stat.FGA : parseFloat(stat.TWO_PA) + parseFloat(stat.THREE_PA);
  stat.FG_PERCENTAGE = stat.FG_PERCENTAGE ? stat.FG_PERCENTAGE : round(percentage(stat.FGM, stat.FGA), 1);
  stat.THREE_PERCENTAGE = stat.THREE_PERCENTAGE
    ? stat.THREE_PERCENTAGE
    : round(percentage(stat.THREE_PM, stat.THREE_PA), 1);
  stat.FT_PERCENTAGE = stat.FT_PERCENTAGE ? stat.FT_PERCENTAGE : round(percentage(stat.FTM, stat.FTA), 1);
};

// ZAOKRUŽI NA ZADANI BROJ DECIMALA
export const round = (string, decimalPlaces) => {
  let number = parseFloat(string);
  return number.toFixed(decimalPlaces);
};

// ZA MINUTE U DECIMALNOM OBLIKU VRATI mm:ss
export const parseMinutes = (minutesSecondsString) => {
  let minutesSeconds = parseFloat(minutesSecondsString).toFixed(2);
  let minutes = Math.floor(minutesSeconds);
  let seconds = ((minutesSeconds - minutes) * 60).toFixed(0);

  let minutesString = minutes / 10 >= 1 ? "" + minutes : "0" + minutes;
  let secondsString = seconds / 10 >= 1 ? "" + seconds : "0" + seconds;
  return minutesString + ":" + secondsString;
};

// IZRAČUNAJ POSTOTAK
export const percentage = (made, attempted) => {
  if (parseFloat(attempted) === 0) {
    return 0;
  }
  return (parseFloat(made) / parseFloat(attempted)) * 100;
};

// FORMATIRAJ SQL DATUM
export const formatDate = (game) => {

  const sqlDate = game.DATE_PLAYED;
  const isRescheduled = game.IS_RESCHEDULED;

  if (!sqlDate) {
    if (isRescheduled===1) {
      return IFNCS_STRING;//RESCHEDULED_STRING;
    } else {
      return TBD_STRING;
    }
  }
  let year = sqlDate.substring(0, 4);
  let month = sqlDate.substring(5, 7);
  let day = sqlDate.substring(8, 10);
  let hour = sqlDate.substring(11, 13);
  let min = sqlDate.substring(14, 16);
  return `${day}.${month}.${year}. ${hour}:${min}`;
};

/**
 * Function that compares values from object a and object b based on the clicked header.
 *
 * @param a object that can contain keys: season, player, team, stat
 * @param b object that can contain keys: season, player, team, stat
 * @param clickedHeader string representing the clicked header
 */
export const headerCompareFunction = (a, b, clickedHeader) => {
  try {
    let sortValue = clickedHeader === "#" ? b.player.JERSEY_NUMBER - a.player.JERSEY_NUMBER : 1;

    sortValue *= clickedHeader === "Season" ? parseFloat(b.season.YEAR) - parseFloat(a.season.YEAR) : 1;

    sortValue *= clickedHeader === "Player" ? -a.player.LAST_NAME.localeCompare(b.player.LAST_NAME) : 1;

    sortValue *= clickedHeader === "Team" ? -a.team.NAME.localeCompare(b.team.NAME) : 1;

    sortValue *= clickedHeader === "GP" ? parseFloat(a.gamesPlayed) - parseFloat(b.gamesPlayed) : 1;
    sortValue *= clickedHeader === "MP" ? parseFloat(a.stat.MP) - parseFloat(b.stat.MP) : 1;
    sortValue *= clickedHeader === "PTS" ? parseFloat(a.stat.PTS) - parseFloat(b.stat.PTS) : 1;
    sortValue *= clickedHeader === "REB" ? parseFloat(a.stat.REB) - parseFloat(b.stat.REB) : 1;
    sortValue *= clickedHeader === "AST" ? parseFloat(a.stat.AST) - parseFloat(b.stat.AST) : 1;
    sortValue *= clickedHeader === "STL" ? parseFloat(a.stat.STL) - parseFloat(b.stat.STL) : 1;
    sortValue *= clickedHeader === "BLK" ? parseFloat(a.stat.BLK) - parseFloat(b.stat.BLK) : 1;

    sortValue *= clickedHeader === "FTM" ? parseFloat(a.stat.FTM) - parseFloat(b.stat.FTM) : 1;
    sortValue *= clickedHeader === "FTA" ? parseFloat(a.stat.FTA) - parseFloat(b.stat.FTA) : 1;

    if (clickedHeader === "FT%") {
      if (a.stat.FTM === b.stat.FTM) {
        sortValue *= parseFloat(b.stat.FTA) - parseFloat(a.stat.FTA);
      } else if (parseFloat(a.stat.FTA) === 0) {
        sortValue *= -1;
      } else if (parseFloat(b.stat.FTA) === 0) {
        sortValue *= 1;
      } else {
        sortValue *= parseFloat(a.stat.FT_PERCENTAGE) - parseFloat(b.stat.FT_PERCENTAGE);
      }
    }

    sortValue *= clickedHeader === "FGM" ? parseFloat(a.stat.FGM) - parseFloat(b.stat.FGM) : 1;
    sortValue *= clickedHeader === "FGA" ? parseFloat(a.stat.FGA) - parseFloat(b.stat.FGA) : 1;

    if (clickedHeader === "FG%") {
      if (parseFloat(a.stat.FGM) === parseFloat(b.stat.FGM)) {
        sortValue *= parseFloat(b.stat.FGA) - parseFloat(a.stat.FGA);
      } else if (parseFloat(a.stat.FGA) === 0) {
        sortValue *= -1;
      } else if (parseFloat(b.stat.FGA) === 0) {
        sortValue *= 1;
      } else {
        sortValue *= parseFloat(a.stat.FG_PERCENTAGE) - parseFloat(b.stat.FG_PERCENTAGE);
      }
    }

    sortValue *= clickedHeader === "3PM" ? parseFloat(a.stat.THREE_PM) - parseFloat(b.stat.THREE_PM) : 1;
    sortValue *= clickedHeader === "3PA" ? parseFloat(a.stat.THREE_PA) - parseFloat(b.stat.THREE_PA) : 1;

    if (clickedHeader === "3PT%") {
      if (parseFloat(a.stat.THREE_PM) === parseFloat(b.stat.THREE_PM)) {
        sortValue *= parseFloat(b.stat.THREE_PA) - parseFloat(a.stat.THREE_PA);
      } else if (parseFloat(a.stat.THREE_PA) === 0) {
        sortValue *= -1;
      } else if (parseFloat(b.stat.THREE_PA) === 0) {
        sortValue *= 1;
      } else {
        sortValue *= parseFloat(a.stat.THREE_PERCENTAGE) - parseFloat(b.stat.THREE_PERCENTAGE);
      }
    }

    sortValue *= clickedHeader === "DRB" ? parseFloat(a.stat.DREB) - parseFloat(b.stat.DREB) : 1;
    sortValue *= clickedHeader === "ORB" ? parseFloat(a.stat.OREB) - parseFloat(b.stat.OREB) : 1;
    sortValue *= clickedHeader === "TO" ? parseFloat(a.stat.TOS) - parseFloat(b.stat.TOS) : 1;
    sortValue *= clickedHeader === "PF" ? parseFloat(a.stat.PF) - parseFloat(b.stat.PF) : 1;
    sortValue *= clickedHeader === "+/-" ? parseFloat(a.stat.PLM) - parseFloat(b.stat.PLM) : 1;

    return sortValue;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Function that sorts given stats based on the clicked header and current state.
 *
 * @param e event (clicked header)
 * @param stats array of stats to be sorted by clicked header
 * @param sortMultiplier number (1 or -1) indicates sort order
 * @param sortedHeader previously sorted header
 */
export const sortStatsTable = (e, stats, sortMultiplier, sortedHeader) => {
  let header = e.target.getAttribute("value");
  let multiplier = sortedHeader === header ? -sortMultiplier : -1;

  let sortedStats = stats.sort((a, b) => multiplier * headerCompareFunction(a, b, header));
  let isAscending = multiplier === 1 ? true : false;
  isAscending =
    header === "#" || header === "Season" || header === "Player" || header === "Team" ? !isAscending : isAscending;
  return [sortedStats, header, multiplier, isAscending];
};

export const initialSortStatsTable = (stats, header, isAscending) => {
  let multiplier = isAscending ? 1 : -1;
  multiplier =
    header === "#" || header === "Season" || header === "Player" || header === "Team" ? -multiplier : multiplier;
  let sortedStats = stats.sort((a, b) => multiplier * headerCompareFunction(a, b, header));
  return [sortedStats, multiplier];
};


/**
 * Function that calculates number of wins and losses for given team and selected games
 * @param teamId Team id for calculating results
 * @param games List of games played by team 
 * @returns 
 */
export const calculateTeamResults = (teamId, games) => {
  let results = { WIN: 0, LOSS: 0 }
  const TEAM_ID = parseInt(teamId);

  games.forEach((game) => {
    const outcome = getGameResult(TEAM_ID, game);
    if (outcome) results[outcome] += 1
  })

  return results;
}

/**
 * Function that determines if a game is won by given team
 * @param teamId Team to check
 * @param game Game played
 * @returns "WIN" if team won, "LOSS" if team lost, null otherwise (upcoming, rescheduled) 
 */
export const getGameResult = (teamId, game) => {
  const TEAM_ID = parseInt(teamId)
  const isHomeWin = parseInt(game.HOME_TEAM_ID) === TEAM_ID & game.HOME_SCORE > game.AWAY_SCORE
  const isAwayWin = parseInt(game.AWAY_TEAM_ID) === TEAM_ID & game.AWAY_SCORE > game.HOME_SCORE

  const isHomeLoss = parseInt(game.HOME_TEAM_ID) === TEAM_ID & game.HOME_SCORE < game.AWAY_SCORE
  const isAwayLoss = parseInt(game.AWAY_TEAM_ID) === TEAM_ID & game.AWAY_SCORE < game.HOME_SCORE

  if (isHomeWin || isAwayWin) return "WIN";
  if (isHomeLoss || isAwayLoss) return "LOSS";

  return null;
}

/**
 * Given the array of teams, and array of games played among them, this function
 * will (recursively) sort the teams by their ranking
 *
 * @param teams array of teams
 * @param games array of games among the given teams
 */
export const sortTeams = (teams, games) => {
  try {
    Object.keys(teams).forEach((key) => {
      teams[key].NAME = key;
      teams[key].W = 0;
      teams[key].L = 0;
      teams[key].WON_GAMES = [];
      teams[key].PLUSMINUS = 0;
    });

    games.forEach((game) => {
      let awayTeam = game.AWAY_TEAM;
      let homeTeam = game.HOME_TEAM;

      let awayScore = game.AWAY_SCORE;
      let homeScore = game.HOME_SCORE;

      teams[awayScore > homeScore ? awayTeam : homeTeam].W += 1;
      teams[awayScore < homeScore ? awayTeam : homeTeam].L += 1;

      teams[awayTeam].PLUSMINUS += awayScore - homeScore;
      teams[homeTeam].PLUSMINUS += homeScore - awayScore;

      teams[awayScore > homeScore ? awayTeam : homeTeam].WON_GAMES.push(game);
    });

    let arrayTeams = [];
    Object.keys(teams).forEach((key) => {
      arrayTeams.push(teams[key]);
    });
    arrayTeams = arrayTeams.sort((a, b) => teamRankingCompareFunction(a, b));

    let startingIndex = 0;
    let finishingIndex = 0;
    for (let i = 0; i < arrayTeams.length - 1; i += 1) {
      if (arrayTeams[i].W === arrayTeams[i + 1].W && arrayTeams[i].L === arrayTeams[i + 1].L) {
        finishingIndex = i + 1;
      } else {
        if (finishingIndex - startingIndex > 0) {
          //CREATE NEW TEAMS CIRCLE
          let teamsCircle = {};
          for (let j = startingIndex; j <= finishingIndex; j += 1) {
            teamsCircle[arrayTeams[j].NAME] = {
              ID: arrayTeams[j].ID,
              ACTIVE: arrayTeams[j].ACTIVE,
            };
          }
          // GET TEAMS CIRCLE GAMES
          let circleGames = [];
          let teamsCircleKeys = Object.keys(teamsCircle);
          for (let j = startingIndex; j <= finishingIndex; j += 1) {
            arrayTeams[j].WON_GAMES.forEach((game) => {
              if (teamsCircleKeys.includes(game.AWAY_TEAM) && teamsCircleKeys.includes(game.HOME_TEAM)) {
                circleGames.push(game);
              }
            });
          }
          // SORT TEAMS CIRCLE
          teamsCircle = sortTeams(teamsCircle, circleGames);
          // APPLY SORTION ON CURRENT TEAMS
          for (let j = 0; j < teamsCircle.length; j += 1) {
            for (let k = 0; k <= teamsCircle.length; k += 1) {
              if (arrayTeams[startingIndex + k].ID === teamsCircle[j].ID) {
                let temp = arrayTeams[startingIndex + j];
                arrayTeams[startingIndex + j] = arrayTeams[startingIndex + k];
                arrayTeams[startingIndex + k] = temp;
                break;
              }
            }
          }
        }
        startingIndex = i + 1;
      }
    }
    return arrayTeams;
  } catch (error) {
    return [];
  }
};

/**
 * Given two teams, the function compares them by their rank
 *
 * @param a object containing a team
 * @param b object containing a team
 */
export const teamRankingCompareFunction = (a, b) => {
  if (b.ACTIVE === 0 && a.ACTIVE === 1) {
    return -1;
  } else if (a.ACTIVE === 0 && b.ACTIVE === 1) {
    return 1;
  } else {
    if (a.W > b.W) {
      return -1;
    } else if (a.W < b.W) {
      return 1;
    } else {
      if (a.L < b.L) {
        return -1;
      } else if (a.L > b.L) {
        return 1;
      } else {
        if (a.PLUSMINUS > b.PLUSMINUS) {
          return -1;
        } else {
          return 1;
        }
      }
    }
  }
};
/**
 * Function for checking if a game has been played. A game is
 * considered finished if at least one team scored a basket.
 * For example:
 *    Finished: 0-20, 1-0, 55-43
 *    Not finished: null-20, undefined-undefined, 0-0 (rescheduled)
 *
 * @param game object containing game information
 */
export const isGameFinished = (game) => {
  if (game.HOME_SCORE === null || game.HOME_SCORE === undefined) {
    return false;
  }

  if (game.AWAY_SCORE === null || game.AWAY_SCORE === undefined) {
    return false;
  }

  if (!game.HOME_SCORE && !game.AWAY_SCORE) {
    return false;
  }

  return true;
};

/**
 * Function for checking if a game has been rescheduled and is not yet played
 * 
 * @param game object containing game information 
 * @returns 
 */
export const isGameRescheduled = (game) => {

  if (game.IS_RESCHEDULED & game.HOME_SCORE === 0 & game.AWAY_SCORE === 0) {
    return true;
  }
  return false;
}


/**
 * Function for determining number of teams entering playoffs
 */
 export const getPlayoffTeams = (seasonId) => {
   
  if (seasonId >= 5) {
     return SHORT_NUMBER_OF_PLAYOFF_TEAMS;
   }
   
   return REGULAR_NUMBER_OF_PLAYOFF_TEAMS;
 } 


 /**
 * Convert JSON keys to uppercase
 * @param {*} data JSON data
 * @param {*} case_ UPPERCASE_KEYS | LOWERCASE_KEYS
 */
export const convertJSONKeysToUppercase = (data, case_) => {
  
  const t0 = performance.now();
  let json = JSON.stringify(data);
  const uppercaseJSON = json.replace(/"([\w]+)":/g, function ($0, $1) {
    if (case_ === UPPERCASE_KEYS) {
      return '"' + $1.toUpperCase() + '":';
    } else if (case_ === LOWERCASE_KEYS) {
      return '"' + $1.toLowerCase() + '":';
    } else {
      return '"' + $1 + '":';
    }
  });
  // Warn if json takes too long
  const time = Math.round((performance.now()-t0)) / 100
  const TIME_LIMIT = 0.2
  time > TIME_LIMIT && console.warn(`JSON Conversion took a bit longer than expected (${time}s)!`);
  
  return JSON.parse(uppercaseJSON);
};

/**
 * Calculate votes percentage and format totalVotes / possibleVotes as XX.xx
 * @param {*} totalVotes 
 * @param {*} possibleVotes 
 */
export const formatVotesPercentage = (totalVotes, possibleVotes) => {
  return (totalVotes / possibleVotes * 100).toFixed(2);
}