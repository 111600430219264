import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import AllStarTable from "../../components/Tables/AllStarTable.jsx";
import VotingResultsTable from "../../components/Tables/VotingResultsTable.jsx";
import VotingLogin from "./VotingLogin.jsx";
import {
  calculateFgRebPercentage,
  calculateTeamResults,
  getGameResult,
  getQueryParams,
  getStatsAvg,
  getStatsTotal,
  initialSortStatsTable,
  isGameFinished,
  isGameRescheduled,
  sortStatsTable,
  updateQuery,
} from "../../constants/utils";

import ALL_STAR_ORIGINAL from "../../images/allstar_original_logo_nobackground.png";
import ALL_STAR_DIS from "../../images/allstar_disabled_logo_nobackground.png";
import { VOTING_FINISHED_FLAG, VOTING_OPENED_FLAG, VOTING_STARTED_FLAG } from "../../constants/constants.js";
// import ALL_STAR_RETRO from "../../images/allstar_retro.png";
// import ALL_STAR_LIGHT from "../../images/allstar_light.png";

const DEFAULT_SORT_HEADER = "pts";
const DEFAULT_IS_ASCENDING = true;

class VotingPage extends Component {
  state = {
    selectedSeason: { ID: 6, YEAR: 2022 },
    voterId: null,
    voter: {},
    votingResults: [],
    votingClosed: false,

    index: 1,
    team: {},
    teams: [],
    players: [],
    playersStats: [],
    regSeasonGames: [],
    regSeasonResults: [],
    contestId: 1,
    isVoting: false,
    isBlocked: false,
    totalVotesRequired: 20,
    arbitraryVotesRemaining: 0,

    sortedHeader: DEFAULT_SORT_HEADER,
    isAscending: DEFAULT_IS_ASCENDING,
    sortMultiplier: -1,
  };

  componentDidMount = () => {
    const queryParams = getQueryParams();
    const sortedHeader = queryParams.get("sort") || DEFAULT_SORT_HEADER;
    const isAscending = queryParams.get("asc")
      ? queryParams.get("asc") === "true"
      : DEFAULT_IS_ASCENDING;
    const voterId = queryParams.get("voterId");
    this.setState({ sortedHeader, isAscending, voterId });

    if (voterId) {
      this.getVoter(voterId).then(() => {
        this.refreshPage();
      });
    }
  };

  refreshPage = () => {
    this.getVoters().then(() => {
      this.getVotingResults(this.state.voterId).then(() => {
        this.getPlayers();
      });
    });
  };

  getVoter = (voterId) => {
    return axiosInstance.get(`/voters/${voterId}`).then((result) => {
      const voter = result.data;
      if (voter.VOTINGPROGRESS === VOTING_FINISHED_FLAG) {
        this.setState({ votingClosed: true, voter });
      } else {
        this.setState({ votingClosed: false, voter})
      }
      if (voter.VOTINGPROGRESS === null) {
        this.patchVoter(VOTING_OPENED_FLAG);
      }
    });
  };

  getVoters = () => {
    return axiosInstance.get(`/voters`).then((result) => {
      const { voter, index } = this.state;
      const teams_ = result.data.filter(
        (voter_) => voter_.TEAMID !== voter.TEAMID
      );
      let teams = teams_.map((team) => ({TEAMID: team.TEAMID, NUMOFPLAYERS: team.NUMOFPLAYERS, NAME: team.TEAM.NAME}));
      const uniqueTeamIds = [];

      teams = teams.filter(team => {
        const isDuplicate = uniqueTeamIds.includes(team.TEAMID);

        if (!isDuplicate) {
          uniqueTeamIds.push(team.TEAMID);
          return true;
        }
        return false;
      });
      
      const team = teams[index - 1];
      //const totalVotesRequired = teams.map((team) => team.NUMOFPLAYERS).reduce((a, b) => a + b);

      this.setState({ teams, team });
    });
  };

  getVotingResults = (voterId) => {
    return axiosInstance.get(`/votes/${voterId}`).then((result) => {
      const votingResults = result.data;
      const { team } = this.state;

      const arbitraryVotesRemaining = this.getArbitraryVotesRemaining(votingResults);
      const selectedPlayersFromTeam = votingResults?.filter((result) => result.VOTEDTEAMID === team.TEAMID).length;

      const isBlocked = (selectedPlayersFromTeam >= team.NUMOFPLAYERS) && arbitraryVotesRemaining === 0;
      this.setState({ votingResults, isBlocked, arbitraryVotesRemaining });
    });
  };

  getPlayers = () => {
    const { selectedSeason, team } = this.state;
    const teamId = team.TEAMID;

    let players = [];
    axiosInstance.get(`/teams/${teamId}/playerTeamSeasons`).then((result) => {
      players = result.data.filter(
        (pts) => pts.SEASON_ID === selectedSeason.ID
      );

      this.setState({ players });
      this.getPlayersStats(selectedSeason);
      this.getGames(selectedSeason);
    });
  };

  getGames = (season) => {
    if (!season) {
      return;
    }
    const { team } = this.state;
    const teamId = team.TEAMID;

    axiosInstance
      .get("/seasons/" + season.ID + "/teams/" + teamId + "/games")
      .then((result) => {
        let teamGames = result.data;
        let regSeasonGames = teamGames.filter(
          (game) =>
            !game.PLAYOFF_GAME &&
            (isGameFinished(game) || isGameRescheduled(game))
        );

        const regSeasonResults = calculateTeamResults(teamId, regSeasonGames);
        regSeasonGames.map(
          (game) => (game.RESULT = getGameResult(teamId, game))
        );

        this.setState({
          regSeasonGames,
          regSeasonResults,
        });
      });
  };

  getPlayersStats = (season) => {
    if (!season) {
      return;
    }

    const { players } = this.state;
    let playersStats = [];
    let allTeamGameStats = [];

    if (!players) {
      this.setState({ playersStats });
      this.setState({ teamStats: {} });
      return;
    }

    players.forEach((pts) => {
      axiosInstance
        .get(
          "/seasons/" + season.ID + "/players/" + pts.PLAYER_ID + "/gameStats"
        )
        .then((result) => {
          let seasonStats = result.data;
          let seasonGamesPlayed = seasonStats.length;
          let seasonStatsTotal = getStatsTotal(seasonStats);
          let seasonStatsAvg = getStatsAvg(seasonStatsTotal, seasonGamesPlayed);

          allTeamGameStats = allTeamGameStats.concat(seasonStats);

          let playerStats = {};
          playerStats.player = {};
          playerStats.player.ID = pts.PLAYER_ID;
          playerStats.player.FIRST_NAME = pts.FIRST_NAME;
          playerStats.player.LAST_NAME = pts.LAST_NAME;
          playerStats.player.JERSEY_NUMBER = pts.JERSEY_NUMBER;
          playerStats.gamesPlayed = seasonGamesPlayed;
          playerStats.stat = seasonStatsAvg;
          calculateFgRebPercentage(playerStats.stat);
          if (seasonGamesPlayed > 0) { playersStats.push(playerStats); }

          const [sortedStats, sortMultiplier] = initialSortStatsTable(
            playersStats,
            this.state.sortedHeader,
            this.state.isAscending
          );
          this.setState({ playersStats: sortedStats, sortMultiplier });
          this.getTeamStats(allTeamGameStats);
        });
    });
  };

  getTeamStats = (allTeamGameStats) => {
    let numberOfGames = 0;
    let gamesFlags = {};

    allTeamGameStats.forEach((stat) => {
      if (!gamesFlags[stat.GAME_ID]) {
        gamesFlags[stat.GAME_ID] = true;
        numberOfGames += 1;
      }
    });

    let totals = getStatsTotal(allTeamGameStats);
    let avgs = getStatsAvg(totals, numberOfGames);
    let teamStats = {};
    teamStats.stat = avgs;
    teamStats.gamesPlayed = numberOfGames;
    calculateFgRebPercentage(teamStats.stat);
    this.setState({ teamStats });
  };

  getMandatoryVotesRemaining = (votingResults) => {
    const { teams } = this.state;
    let teams_ = {};
    teams.forEach((team) => teams_[team.TEAMID] = team.NUMOFPLAYERS);
    
    votingResults.forEach((result) => {
      if(teams_[result.VOTEDTEAMID] > 0) {
        teams_[result.VOTEDTEAMID] -= 1;
      }
    });

    const mandatoryVotesRemaining = Object.values(teams_).reduce((a, b) => a + b, 0);
    return mandatoryVotesRemaining;
  }

  getArbitraryVotesRemaining = (votingResults) => {

    const { totalVotesRequired } = this.state;
    const mandatoryVotesRemaining = this.getMandatoryVotesRemaining(votingResults)
    const arbitraryVotesRemaining = totalVotesRequired - votingResults.length - mandatoryVotesRemaining;
    
    this.setState({ arbitraryVotesRemaining });

    return arbitraryVotesRemaining;
  }

  handleHeaderClick = (e) => {
    if (e.target.getAttribute("value") === "Vote") return;
    let [sortedStats, sortedHeader, sortMultiplier, isAscending] =
      sortStatsTable(
        e,
        this.state.playersStats,
        this.state.sortMultiplier,
        this.state.sortedHeader
      );
    updateQuery({ sort: sortedHeader, asc: isAscending });
    this.setState({
      playersStats: sortedStats,
      sortMultiplier,
      sortedHeader,
      isAscending,
    });
  };

  handlePageChange = (index) => {
    this.setState({ index }, () => {
      this.refreshPage();
    });
  };

  handleVote = (player, vote) => {
    if (this.state.isVoting) return;
    
    this.setState({ isVoting: true }, () => {
      if (vote) {
        this.saveVote(player.ID);
      } else {
        this.deleteVote(player.ID);
      }
    });
  };

  saveVote = (playerId) => {
    const { voterId, team, contestId } = this.state;
    const teamId = team.TEAMID;

    const votingResult = {
      voterId: voterId,
      votedPlayerId: playerId,
      votedTeamId: teamId,
      contestId: contestId,
    };

    axiosInstance.post("/votes", votingResult).then(() => {
      this.getVotingResults(voterId).then(() => {
        this.patchVoter(VOTING_STARTED_FLAG);
        this.setState({ isVoting: false });
      });
    });
  };

  deleteVote = (playerId) => {
    const { voterId } = this.state;

    axiosInstance.delete(`/players/${playerId}/votes`, { params: { voterId: voterId }}).then(() => {
      this.getVotingResults(voterId).then(() => {
        this.setState({ isVoting: false });
      });
    });
  };

  finishVoting = () => {
    this.patchVoter(VOTING_FINISHED_FLAG).then(() => {
      this.getVoter(this.state.voterId);
    });
  };

  patchVoter = (votingProgress) => {
    const { voterId } = this.state;
    return axiosInstance.patch("/voters", {}, { params: { voterId: voterId, votingProgress: votingProgress }});
  }

  render = () => {
    let { voterId, votingClosed, votingResults, index, teams, team, voter, totalVotesRequired, arbitraryVotesRemaining } = this.state;
    const { PLAYER, TEAM } = voter;

    const playerName = PLAYER ? PLAYER.FIRSTNAME + " " + PLAYER.LASTNAME : "";
    const teamName = TEAM ? TEAM.NAME : "";
    const teamVotes = votingResults.filter((result) => result.VOTEDTEAMID === team.TEAMID).length;
    const isFinished = (totalVotesRequired === votingResults.length);
    //const selectText = (team?.NUMOFPLAYERS - teamVotes) > 0 ? `(Select at least ${team?.NUMOFPLAYERS - teamVotes} more player${team?.NUMOFPLAYERS - teamVotes > 1 ? "s" : ""})` : `${ index === teams.length || isFinished ? "" : "( Vote next >>> )" }`
    const selectText = (team?.NUMOFPLAYERS - teamVotes) > 0 ? `(Odaberite još barem ${team?.NUMOFPLAYERS - teamVotes} igrača iz ove ekipe)` : `${ arbitraryVotesRemaining > 0 ? `(Možete odabrati još najviše ${arbitraryVotesRemaining} igrača iz ove ekipe` : "(Maksimalan broj igrača iz ove ekipe je odabran)"}`
    
    if (!voterId) {
      return <VotingLogin />;
    };

    if (votingClosed) {
      return (
        <React.Fragment>
          <h3
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "2rem",
            }}
          >{`Zahvaljujemo na glasanju, ${playerName} - ${teamName}!`}</h3>  
          <h1 className="text-center">FERBasket All Star 2023 | Konačni Glasovi</h1>
          <VotingResultsTable
            index={index}
            title={""}
            votingResults={votingResults}
            handleVote={() => {}}
            isVoting={true}
            isBlocked={true}
            activeImgSrc={ALL_STAR_ORIGINAL}
            inactiveImgSrc={ALL_STAR_DIS}
          />
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <h3
          style={{
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "2rem",
            marginTop: "10rem"
          }}
        >{`Dobrodošao, ${playerName} | ${teamName}`}</h3>
        <h1 className="text-center">All Star Glasanje</h1>
        <div className="grid-container">
          <div className="grid-item">
            {team.NUMOFPLAYERS ? 
              <h2 className="text-center" style={{marginBottom: "1rem"}}>
                {team.NAME} {selectText}
              </h2>
              : <h2 />
            }
            <div style={{display: "inline"}}>
              {index > 1 && <button className="voting-button" style={{float: "left", marginLeft: "2rem"}} onClick={(e) => {e.preventDefault(); this.handlePageChange(index - 1)}}>{"< " + teams[index-2].NAME} </button>}
              {(team.NUMOFPLAYERS - teamVotes) <= 0 && index < teams.length && <button className="voting-button" style={{float: "right", marginRight: "2rem"}} onClick={(e) => {e.preventDefault(); this.handlePageChange(index + 1)}}>{teams[index].NAME + " >"} </button>}
            </div>
            <AllStarTable
              title={""}
              hasPagination={true}
              handlePageChange={this.handlePageChange}
              currentPage={this.state.index}
              votingResults={votingResults}
              rowsInfo={this.state.playersStats}
              sortedHeader={this.state.sortedHeader}
              isAscending={this.state.isAscending}
              handleHeaderClick={this.handleHeaderClick}
              teams={teams}
              handleVote={this.handleVote}
              isBlocked={this.state.isBlocked}
              isVoting={this.state.isVoting}
              activeImgSrc={ALL_STAR_ORIGINAL}
              inactiveImgSrc={ALL_STAR_DIS}
            />
          </div>
          <div className="grid-item">
            <h2 className="text-center">
              {`All Star Glasovi (${votingResults.length + "/" + totalVotesRequired})`}
              {isFinished && <button className="voting-button" style={{float: "right"}} onClick={this.finishVoting}>Završi glasanje</button>}
            </h2>
            <VotingResultsTable
              index={index}
              votingResults={votingResults}
              handleVote={this.handleVote}
              isVoting={this.state.isVoting}
              isBlocked={this.state.isBlocked}
              activeImgSrc={ALL_STAR_ORIGINAL}
              inactiveImgSrc={ALL_STAR_DIS}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
}

export default VotingPage;
