import React from "react";
import TopPerformanceRow from "../TableRows/TopPerformanceRow";

/**
 * Functional component that returns a table based on the given parameters.
 * Table containing information about players' single game top performances.
 * categoryName indicates which header will be displayed in the table.
 *
 * @param props Object containing all the parameters
 * @param props.categoryKey string with name of category key used to access performance information
 *          (PTS, REB, AST, STL, BLK, THREE_PM)
 * @param props.categoryName string with name of the category displayed in table header
 *          (Poeni, Skokovi, Asistencije, Ukradene lopte, Blokade, Trice)
 * @param props.topPerformances array of objects containing row information
 *          (keys: PLAYER_ID, FIRST_NAME, LAST_NAME, ${categoryName}, GAME_ID, DATE_PLAYED,
 *              HOME_TEAM, AWAY_TEAM, HOME_SCORE, AWAY_SCORE)
 *
 * @returns top performance table
 */

const TopPerformanceTable = (props) => {
  const { categoryName, categoryKey, topPerformances, index } = props;
  const headers = (
    <tr>
      <th style={{ minWidth: "2.5rem", width: "5%" }}>#</th>
      <th className="sticky" style={{ minWidth: "20rem", width: "20%", zIndex: 1}}>Player</th>
      <th style={{ minWidth: "5rem", width: "10%" }}>{categoryName}</th>
      <th style={{ minWidth: "20rem", width: "20%" }}>Date</th>
      <th style={{ minWidth: "50rem", width: "45%" }}>Match</th>
    </tr>
  );

  const emptyTable = (
    <div className="container-table">
      <h2 className="sticky">{categoryName}</h2>
      <table>
        <thead>{headers}</thead>
        <tbody>
          <tr>
            <td className="sticky" colSpan={5}>
              No data available
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  if (topPerformances.length === 0) return emptyTable;

  return (
    <React.Fragment key={index}>
      <div className="container-table">
        <h2 className="sticky">{categoryName}</h2>
        <table>
          <thead>{headers}</thead>
          <tbody>
            {topPerformances.map((performance, i) => (
              <TopPerformanceRow
                key={i}
                index={i + 1}
                performance={performance}
                categoryKey={categoryKey}
              />
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default TopPerformanceTable;
