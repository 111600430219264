import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import GamesList from "../../components/Games/GamesList.jsx";
import Table from "../../components/Tables/Table.jsx";
import SeasonSelect from "../../components/utils/SeasonSelect.jsx";
import {
  calculateFgRebPercentage,
  calculateTeamResults,
  getGameResult,
  getQueryParams,
  getStatsAvg,
  getStatsTotal,
  initialSortStatsTable,
  isGameFinished,
  isGameRescheduled,
  sortStatsTable,
  updateQuery,
} from "../../constants/utils";

const DEFAULT_SORT_HEADER = "#";
const DEFAULT_IS_ASCENDING = true;

class TeamProfile extends Component {
  state = {
    TEAM_ID: this.props.teamId,
    team: {},
    resourceNotFound: false,
    errorMsg: "",

    seasons: [],
    selectedSeason: undefined,

    playersBySeason: {},
    playersStats: [],

    teamStats: {},
    regSeasonGames: [],
    regSeasonUpcommingGames: [],
    playoffGames: [],
    playoffUpcommingGames: [],

    regSeasonResults: { WIN: "", LOSS: ""},
    playoffResults: { WIN: "", LOSS: "" },

    sortedHeader: DEFAULT_SORT_HEADER,
    isAscending: DEFAULT_IS_ASCENDING,
    sortMultiplier: -1,

    showTotals: false,
  };

  componentDidMount = () => {
    const queryParams = getQueryParams();
    const sortedHeader = queryParams.get("sort") || DEFAULT_SORT_HEADER;
    const isAscending = queryParams.get("asc") ? queryParams.get("asc") === "true" : DEFAULT_IS_ASCENDING;
    this.setState({ sortedHeader, isAscending });
    this.getTeam().then(() => {
      this.getPlayers();
    });
  };

  getTeam = () => {
    return axiosInstance.get("/teams/" + this.state.TEAM_ID).then((result) => {
      let team = result.data;
      this.setState({ team });
    });
  };

  getPlayers = () => {
    let playersBySeason = {};
    let seasons = [];
    axiosInstance.get("/teams/" + this.state.TEAM_ID + "/playerTeamSeasons").then((result) => {
      let playerTeamSeasons = result.data;
      playerTeamSeasons.forEach((pts) => {
        if (playersBySeason[pts.YEAR]) {
          playersBySeason[pts.YEAR].push(pts);
        } else {
          playersBySeason[pts.YEAR] = [];
          let season = {};
          season.ID = pts.SEASON_ID;
          season.YEAR = pts.YEAR;
          seasons.push(season);
          playersBySeason[pts.YEAR].push(pts);
        }
      });
      seasons.sort((a, b) => b.YEAR - a.YEAR);
      const selectedSeasonYear = parseInt(getQueryParams().get("season"));
      let selectedSeason = seasons.find((season) => season.YEAR === selectedSeasonYear) || seasons[0];
      this.setState({ selectedSeason });
      this.setState({ seasons, playersBySeason });
      this.getPlayersStats(this.state.selectedSeason);
      this.getGames(selectedSeason);
    });
  };

  getGames = (season) => {
    if (!season) {
      return;
    }
    const { TEAM_ID } = this.state;
    
    axiosInstance.get("/seasons/" + season.ID + "/teams/" + TEAM_ID + "/games").then((result) => {
      let teamGames = result.data;
      let regSeasonGames = teamGames.filter((game) => !game.PLAYOFF_GAME && (isGameFinished(game) || isGameRescheduled(game)) );
      let regSeasonUpcommingGames = teamGames.filter((game) => !game.PLAYOFF_GAME && (!isGameFinished(game) && !isGameRescheduled(game)));
      let playoffGames = teamGames.filter((game) => game.PLAYOFF_GAME && (isGameFinished(game) || isGameRescheduled(game)));
      let playoffUpcommingGames = teamGames.filter((game) => game.PLAYOFF_GAME && (!isGameFinished(game) && !isGameRescheduled(game)));

      const regSeasonResults = calculateTeamResults(TEAM_ID, regSeasonGames);
      const playoffResults = calculateTeamResults(TEAM_ID, playoffGames);

      regSeasonGames.map((game) => (game.RESULT = getGameResult(TEAM_ID, game)));
      playoffGames.map((game) => (game.RESULT = getGameResult(TEAM_ID, game)));

      this.setState({
        regSeasonGames,
        playoffGames,
        regSeasonUpcommingGames,
        playoffUpcommingGames,
        regSeasonResults,
        playoffResults
      });
    });
  };

  getPlayersStats = (season) => {
    if (!season) {
      return;
    }
    let playersStats = [];
    let allTeamGameStats = [];
    if (!this.state.playersBySeason[season.YEAR]) {
      this.setState({ playersStats });
      this.setState({ teamStats: {} });
      return;
    }
    this.state.playersBySeason[season.YEAR].forEach((pts) => {
      axiosInstance.get("/seasons/" + season.ID + "/players/" + pts.PLAYER_ID + "/gameStats").then((result) => {
        let seasonStats = result.data;
        let seasonGamesPlayed = seasonStats.length;
        let seasonStatsTotal = getStatsTotal(seasonStats);
        let seasonStatsAvg = getStatsAvg(seasonStatsTotal, seasonGamesPlayed);

        allTeamGameStats = allTeamGameStats.concat(seasonStats);

        let playerStats = {};
        playerStats.player = {};
        playerStats.player.ID = pts.PLAYER_ID;
        playerStats.player.FIRST_NAME = pts.FIRST_NAME;
        playerStats.player.LAST_NAME = pts.LAST_NAME;
        playerStats.player.JERSEY_NUMBER = pts.JERSEY_NUMBER;
        playerStats.gamesPlayed = seasonGamesPlayed;
        playerStats.stat = this.state.showTotals ? seasonStatsTotal : seasonStatsAvg;
        calculateFgRebPercentage(playerStats.stat);
        playersStats.push(playerStats);

        const [sortedStats, sortMultiplier] = initialSortStatsTable(
          playersStats,
          this.state.sortedHeader,
          this.state.isAscending
        );
        this.setState({ playersStats: sortedStats, sortMultiplier });
        this.getTeamStats(allTeamGameStats);
      });
    });
  };

  getTeamStats = (allTeamGameStats) => {
    let numberOfGames = 0;
    let gamesFlags = {};

    allTeamGameStats.forEach((stat) => {
      if (!gamesFlags[stat.GAME_ID]) {
        gamesFlags[stat.GAME_ID] = true;
        numberOfGames += 1;
      }
    });

    let totals = getStatsTotal(allTeamGameStats);
    let avgs = getStatsAvg(totals, numberOfGames);
    let teamStats = {};
    teamStats.stat = this.state.showTotals ? totals : avgs;
    teamStats.gamesPlayed = numberOfGames;
    calculateFgRebPercentage(teamStats.stat);
    this.setState({ teamStats });
  };

  handleSeasonSelect = (e) => {
    let seasons = this.state.seasons;
    let selectedSeason = seasons.find((season) => season.ID === parseInt(e.target.value));

    this.setState({ selectedSeason });
    this.getPlayersStats(selectedSeason);
    this.getGames(selectedSeason);
  };

  handleHeaderClick = (e) => {
    let [sortedStats, sortedHeader, sortMultiplier, isAscending] = sortStatsTable(
      e,
      this.state.playersStats,
      this.state.sortMultiplier,
      this.state.sortedHeader
    );
    updateQuery({ sort: sortedHeader, asc: isAscending });
    this.setState({
      playersStats: sortedStats,
      sortMultiplier,
      sortedHeader,
      isAscending,
    });
  };

  handleTotals = () => {
    this.setState({
      showTotals: !this.state.showTotals}, () => this.getPlayersStats(this.state.selectedSeason)
    ); 
  };

  render = () => {
    let { regSeasonGames, regSeasonUpcommingGames, playoffGames,
      playoffUpcommingGames, regSeasonResults, team } = this.state;

    return (
      <div>
        <h1 className="text-center">{`${team.NAME} (${regSeasonResults.WIN}W-${regSeasonResults.LOSS}L)`}</h1>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem"}}>
          <SeasonSelect
            handleSeasonSelect={this.handleSeasonSelect}
            selectedSeason={this.state.selectedSeason}
            seasons={this.state.seasons}
          />
          <button className="totals-button" onClick={this.handleTotals}>
            {this.state.showTotals ? "Average Stats >>" : "Total Stats >>"}
          </button>
        </div>

        
        <Table
          title={"Statistics"}
          showJerseyNumber={true}
          stringInsteadOfJerseyNumber={"-"}
          showPlayerName={true}
          stringInsteadOfPlayerName={"Total"}
          showGamesPlayed={true}
          shouldRoundStats={!this.state.showTotals}
          rowsInfo={this.state.playersStats}
          showRowsTotal={true}
          rowsTotal={this.state.teamStats}
          sortedHeader={this.state.sortedHeader}
          isAscending={this.state.isAscending}
          showPersonalFauls={true}
          handleHeaderClick={this.handleHeaderClick}
        />

        {regSeasonGames.length > 0 && (
          <GamesList
            title="Regular season games"
            games={regSeasonGames}
            isPlayoff={false}
            isUpcomming={false}
            fontSize="normal"
          />
        )}
        {regSeasonUpcommingGames.length > 0 && (
          <GamesList
            title="Upcoming regular sesason games"
            games={regSeasonUpcommingGames}
            isPlayoff={false}
            isUpcomming={true}
            fontSize="normal"
          />
        )}
        {playoffGames.length > 0 && (
          <GamesList
            title={`Playoff games`}
            games={playoffGames}
            isPlayoff={true}
            isUpcomming={false}
            fontSize="normal"
          />
        )}
        {playoffUpcommingGames.length > 0 && (
          <GamesList
            title="Upcoming playoff games"
            games={playoffUpcommingGames}
            isPlayoff={true}
            isUpcomming={true}
            fontSize="normal"
          />
        )}
      </div>
    );
  };
}

export default TeamProfile;
