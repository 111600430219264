import React from "react";
import styled from "styled-components";
import { updateQuery } from "../../constants/utils";

const SelectContainer = styled.div`
  max-width: 180px;
  height: 3rem;
  margin: 0 1rem;
`;

const Select = styled.select`
  border: 1px solid #003d68;
  border-radius: 3px;
  color: #002a52;
  background-color: white;
  width: 100%;
  height: 100%;
  font-size: 2rem;
  font-weight: 300;
  outline: none;
`;

const Option = styled.option`
  font-size: 2rem;
  font-weight: 300;
  &:hover &:checked {
    background-color: #002a52;
  }
`;

const SeasonSelect = (props) => {

  return (
    <SelectContainer>
      <Select
        name="season"
        value={props.selectedSeason?.ID}
        id="season"
        onChange={(e) => {
          e.preventDefault();
          props.handleSeasonSelect(e);
          let selectedSeason = props.seasons.find((season) => season.ID === parseInt(e.target.value));
          updateQuery({ season: selectedSeason.YEAR });
        }}
      >
        {props.seasons.map((season, i) => (
          <Option
            key={i}
            value={season.ID}
          >
            {season.YEAR}/{parseInt(season.YEAR) + 1}
          </Option>
        ))}
      </Select>
    </SelectContainer>
  );
};

export default SeasonSelect;
