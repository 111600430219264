import React, { useState } from "react";
import styled from "styled-components";
import Dropdown from "../utils/Dropdown.jsx";
import RightNav from "./RightNav.jsx";

const BurgerWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin: 0 1rem;
  @media (max-width: 1024px) {
    display: flex;
  }
`;

const StyledBurger = styled.div`
  width: 3rem;
  height: 3rem;
  position: absolute;
  right: 2rem;
  z-index: 99;
  display: none;

  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;

  div {
    width: 3rem;
    height: 0.3rem;
    background-color: ${({ open }) => (open ? "white" : "white")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(0)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = (props) => {
  const [open, setOpen] = useState(false);
  let { teams } = props;

  const items = teams.map((team) => {
    const item = {};
    item.href = "/teams/" + team.ID;
    item.text = team.NAME;
    return item;
  });

  return (
    <BurgerWrapper>
      <Dropdown text="Teams" items={items} openOnHover={false} />
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} {...props} />
    </BurgerWrapper>
  );
};

export default Burger;
