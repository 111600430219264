import React from "react";
import { formatDate } from "../../constants/utils";
/**
 * Functional component that returns row in a table based on the given parameters.
 * showSomething parameters should be used when the row displays individual statistics.
 * If the row displays total statistics (example: team's totals as a sum of players' stats), then
 * showSomething parameters should be false, and stringInsteadOfSomething parameters should be set
 * to values to be displayed.
 *
 * @param props Object containing all the parameters
 * @param props.index integer indicating index/rank of given performance
 * @param props.performance object containing player and game information
 * @param props.categoryKey string with name of category key used to access performance information
 *          (PTS, REB, AST, STL, BLK, THREE_PM)
 * @returns top performance table row
 */

const TopPerformanceRow = (props) => {
  let { index, performance, categoryKey } = props;
  return (
    <tr>
      <td>{index}.</td>
      <td className="text-left sticky">
        <a href={"/players/" + performance.PLAYER_ID}>
          {performance.FIRST_NAME + " " + performance.LAST_NAME}
        </a>
      </td>
      <td>{performance[categoryKey]}</td>
      <td>
        <a href={"/games/" + performance.GAME_ID}>
          {formatDate(performance)}h
        </a>
      </td>
      <td className="text-left">
        <a href={"/teams/" + performance.HOME_TEAM_ID}>{performance.HOME_TEAM}</a>
        vs
        <a href={"/teams/" + performance.AWAY_TEAM_ID}>{performance.AWAY_TEAM}</a>
        {" ("}
        {performance.HOME_SCORE + " : " + performance.AWAY_SCORE + ")"}
      </td>
    </tr>
  );
};

export default TopPerformanceRow;
