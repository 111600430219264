import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance";
import TopPerformanceTable from "../../components/Tables/TopPerformanceTable";
import { SearchBox, SearchBoxContainer } from "../../components/utils/SearchBox";
import { topPerformanceCategories } from "../../constants/constants";

class TopGamePerformance extends Component {
  state = {
    topPerformances: {},
    topNPlayers: 10,
    searchFilter: "-",
  };

  componentDidMount = () => {
    this.loadScreen();
  };

  loadScreen = () => {
    let { topNPlayers } = this.state;
    Object.keys(topPerformanceCategories).forEach((category) => {
      this.getTopGamePerformances(category, topNPlayers);
    });
  }

  getTopGamePerformances = async (category, topNPlayers) => {
    let { searchFilter } = this.state;
    const res = await axiosInstance.get(`/topGameStats?category=${category}&numOfRows=${topNPlayers}&filter=${searchFilter}`);
    let topPerformances = this.state.topPerformances;
    topPerformances[category] = res.data;

    this.setState({ topPerformances });
  };

  handleFilterChange = (e) => {
    this.setState({ searchFilter: e.target.value }, () => { this.loadScreen() });
    
  };

  render() {
    let categories = Object.keys(topPerformanceCategories);

    return (
      <React.Fragment>
        
        <div style={{ textAlign: "center" }}>
          <h2 style={{ textAlign: "center" }}>Top {this.state.topNPlayers} Individual Performances</h2>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "2rem" }}>
            <SearchBoxContainer>
              <SearchBox type="text" placeholder="Find player..." onChange={(e) => this.handleFilterChange(e)} />
            </SearchBoxContainer>
          </div>
        </div>
        <div>
          {categories.map((key, i) => (
            <TopPerformanceTable
              key={i}
              topNPlayers={this.state.topNPlayers}
              topPerformances={this.state.topPerformances[key] || []}
              categoryName={topPerformanceCategories[key]}
              categoryKey={key}
              index={i}
            />
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default TopGamePerformance;