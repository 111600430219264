import React from "react";
import { ALL_STARS_STATS_TABLE_HEADERS_WIDTH } from "../../constants/constants";
import { round, parseMinutes } from "../../constants/utils";

/**
 * Functional component that returns row in a table based on the given parameters.
 *
 * @param props Object containing all the parameters
 * @param props.rowKey table row key
 * @param props.gamesPlayed number of games played
 * @param props.stats object containing stats
 * @param props.player object containing player
 * @param props.isVoted boolean to check if player is already voted for All Star
 * @param props.isBlocked boolean to limit number of players team captains can select for specific team
 * @param props.handleVote function to vote or remove All Star vote for given player
 * @param props.activeImgSrc image for active/voted All Star player
 * @param props.inactiveImgSrc image for inactive/unvoted All Star player
 *
 * @returns table row
 */
const AllStarRow = (props) => {
  let {
    rowKey,
    gamesPlayed,
    stats,
    player,
    isVoted,
    isBlocked,
    handleVote,
    activeImgSrc,
    inactiveImgSrc,
  } = props;

  if (!stats) { return null; }

  Object.keys(stats).forEach((key) => {
    try {
      stats[key] = round(stats[key], 1);
    } catch (error) {}
  });

  return (
    <tr key={rowKey}>
      <td>
        <button onClick={(e) => {e.preventDefault(); (!isBlocked || isVoted) && handleVote(player, !isVoted); }} className={`all-star-button${isBlocked && !isVoted ? "-blocked" : ""}`}>
          {isVoted ? <img src={activeImgSrc} style={{maxWidth: "3rem" }} />
          : <img src={inactiveImgSrc} style={{maxWidth: "3rem"}} />}
        </button>
      </td>
      
      {/* <td style={{ width: ALL_STARS_STATS_TABLE_HEADERS_WIDTH[1] + "rem" }}>{player.JERSEY_NUMBER}</td> */}

      <td className="text-left sticky" style={{ maxWidth: ALL_STARS_STATS_TABLE_HEADERS_WIDTH[1] + "rem" }}>
          <a href={"/players/" + player.ID} target="_blank" >
          {player.LAST_NAME}, {player.FIRST_NAME}
          </a>
      </td>
      <td>{gamesPlayed}</td>
      <td>{parseMinutes(stats.MP)}</td>
      <td>{stats.PTS}</td>
      <td>{stats.REB}</td>
      <td>{stats.AST}</td>
      <td>{stats.STL}</td>
      <td>{stats.BLK}</td>

      <td>{stats.FG_PERCENTAGE}</td>
      <td>{stats.THREE_PERCENTAGE}</td>
      <td>{stats.FT_PERCENTAGE}</td>

      <td>{stats.OREB}</td>
      <td>{stats.TOS}</td>
    </tr>
  );
};

export default AllStarRow;
