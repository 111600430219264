import React from "react";
import { STATS_TABLE_HEADERS_WIDTH } from "../../constants/constants";
import { round, parseMinutes, calculateFgRebPercentage } from "../../constants/utils";

/**
 * Functional component that returns row in a table based on the given parameters.
 * showSomething parameters should be used when the row displays individual statistics.
 * If the row displays total statistics (example: team's totals as a sum of players' stats), then
 * showSomething parameters should be false, and stringInsteadOfSomething parameters should be set
 * to values to be displayed.
 *
 * @param props Object containing all the parameters
 * @param props.rowKey table row key
 * @param props.hasPagination boolean indicating that row is inside a paginated table
 * @param props.index index of this row in a paginated table
 * @param props.showJerseyNumber boolean indicating display of player's jersey number
 * @param props.stringInsteadOfJerseyNumber string which when defined replaces player's jersey number
 * @param props.showSeason boolean indicating display of season
 * @param props.stringInsteadOfSeason string which when defined replaces season
 * @param props.showPlayerName boolean indicating display of player's name
 * @param props.stringInsteadOfPlayerName string which when defined replaces player's name
 * @param props.isStarter boolean indicating if the player is a starter
 * @param props.showTeam boolean indicating display of team name
 * @param props.showTeam boolean indicating display of player's matchup
 * @param props.stringInsteadOfTeam string which when defined replaces team name
 * @param props.showDate boolean indicating display of date
 * @param props.stringInsteadOfDate string which when defined replaces date
 * @param props.showGamesPlayed boolean indicating display of games played
 * @param props.gamesPlayed number of games played
 * @param props.shouldRoundStats boolean indicating if the stats should be rounded
 * @param props.stats object containing stats
 * @param props.season object containing season
 * @param props.player object containing player
 * @param props.team object containing team
 * @param props.game object containing game (ID and DATE)
 *
 * @returns table row
 */
const Row = (props) => {
  let {
    rowKey,
    hasPagination,
    index,
    showJerseyNumber,
    stringInsteadOfJerseyNumber,
    showSeason,
    stringInsteadOfSeason,
    showPlayerName,
    stringInsteadOfPlayerName,
    isStarter,
    showTeam,
    showMatchup,
    stringInsteadOfTeam,
    showDate,
    stringInsteadOfDate,
    showGamesPlayed,
    gamesPlayed,
    showPersonalFauls,
    showPlusMinus,
    shouldRoundStats,
    stats,
    season,
    player,
    team,
    game,
  } = props;

  if (!stats) {
    return null;
  }
  calculateFgRebPercentage(stats);

  if (shouldRoundStats) {
    Object.keys(stats).forEach((key) => {
      try {
        stats[key] = round(stats[key], 1);
      } catch (error) {}
    });
  }

  return (
    <tr key={rowKey}>
      {hasPagination && <td style={{ width: STATS_TABLE_HEADERS_WIDTH[0] + "rem" }}>{index}</td>}
      {showJerseyNumber && <td style={{ width: STATS_TABLE_HEADERS_WIDTH[0] + "rem" }}>{player.JERSEY_NUMBER}</td>}
      {stringInsteadOfJerseyNumber && <td>{stringInsteadOfJerseyNumber}</td>}

      {showSeason && (
        <td>
          {season.YEAR}/{parseFloat(season.YEAR) + 1}
        </td>
      )}
      {stringInsteadOfSeason && <td>{stringInsteadOfSeason}</td>}

      {showPlayerName && (
        <td className="text-left sticky" style={{ maxWidth: STATS_TABLE_HEADERS_WIDTH[2] + "rem" }}>
          <a href={"/players/" + player.ID}>
            {player.LAST_NAME}, {player.FIRST_NAME}
            {isStarter && "*"}
          </a>
        </td>
      )}
      {stringInsteadOfPlayerName && <td className="sticky">{stringInsteadOfPlayerName}</td>}

      {showTeam && (
        <td className="text-left" style={{ maxWidth: STATS_TABLE_HEADERS_WIDTH[3] + "rem" }}>
          <a href={"/teams/" + team.ID}>{team.NAME}</a>
        </td>
      )}
      {showMatchup && (
        <td className="text-left" style={{ paddingLeft: "1rem", maxWidth: STATS_TABLE_HEADERS_WIDTH[4] + "rem" }}>
          vs<a href={"/teams/" + team.ID}>{team.NAME}</a>
        </td>
      )}
      {stringInsteadOfTeam && <td>{stringInsteadOfTeam}</td>}

      {showDate && (
        <td style={{ maxWidth: STATS_TABLE_HEADERS_WIDTH[5] + "rem" }}>
          <a href={"/games/" + game.ID}>{game.DATE}</a>
        </td>
      )}
      {stringInsteadOfDate && <td>{stringInsteadOfDate}</td>}

      {showGamesPlayed && <td>{gamesPlayed}</td>}
      <td>{parseMinutes(stats.MP)}</td>
      <td>{stats.PTS}</td>
      <td>{stats.REB}</td>
      <td>{stats.AST}</td>
      <td>{stats.STL}</td>
      <td>{stats.BLK}</td>

      <td>{stats.FGM}</td>
      <td>{stats.FGA}</td>
      <td>{stats.FG_PERCENTAGE}</td>

      <td>{stats.THREE_PM}</td>
      <td>{stats.THREE_PA}</td>
      <td>{stats.THREE_PERCENTAGE}</td>

      <td>{stats.FTM}</td>
      <td>{stats.FTA}</td>
      <td>{stats.FT_PERCENTAGE}</td>

      <td>{stats.DREB}</td>
      <td>{stats.OREB}</td>
      <td>{stats.TOS}</td>
      {showPersonalFauls && <td>{stats.PF}</td>}
      {showPlusMinus && (
        <td>
          {stats.PLM > 0 ? "+" : ""}
          {stats.PLM}
        </td>
      )}
    </tr>
  );
};

export default Row;
