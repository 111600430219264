import React, { Component } from "react";
import styled from "styled-components";
import { SearchBox, SearchBoxContainer } from "./SearchBox";

const Suggestions = styled.ul`
  display: block;
  border: 1px solid #999;
  border-radius: 2px 2px 2px 2px;
  border-top-width: 0;
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
  width: calc(100% + 1rem);
  height: ${({ numberOfSuggestions }) => numberOfSuggestions * 3.4 + "rem"};
  box-shadow: ${({ showSuggestions }) => (showSuggestions ? "0px 2px 8px 2px rgb(0, 0, 0, 0.25)" : "none")};
  max-height: 32rem;
  overflow-y: scroll;
`;

const SuggestionItem = styled.li`
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  height: 3.4rem;
  line-height: 3.4rem;
  font-size: 1.6rem;
  margin: 0; 
  padding: 0 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    background-color: #003d68;
    color: white;
    cursor: pointer;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
`;

class AutocompleteSearch extends Component {
  state = {
    suggestions: this.props.suggestion,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: "",
    shouldRedirect: false,
    redirectLink: "",
  };

  componentDidMount = () => {
    document.addEventListener("click", (e) => {
      this.setState({ showSuggestions: false });
    });
  };


  onChange = (e) => {
    const { suggestions } = this.props;
    const search = e.currentTarget.value;
    const filteredSuggestions = suggestions.filter(
      (suggestion) =>
        (suggestion.NAME && suggestion.NAME.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
        (suggestion.FIRST_NAME &&
          suggestion.LAST_NAME &&
          (suggestion.FIRST_NAME + " " + suggestion.LAST_NAME).toLowerCase().indexOf(search.toLowerCase()) > -1)
    );
    this.setState({
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
  };

  onClick = (suggestion) => {
    if (suggestion.NAME) {
      window.location.replace("/teams/" + suggestion.ID);
    } else {
      window.location.replace("/players/" + suggestion.ID);
    }
  };

  render() {
    const { filteredSuggestions, showSuggestions, userInput } = this.state;

    let suggestionsListComponent;

    if (showSuggestions && userInput && filteredSuggestions.length) {
      suggestionsListComponent = (
        <Suggestions numberOfSuggestions={filteredSuggestions.length} showSuggestions={this.state.showSuggestions}>
          {filteredSuggestions.map((suggestion, index) => {
            return (
              <SuggestionItem key={index} onClick={() => this.onClick(suggestion)}>
                {suggestion.NAME || suggestion.FIRST_NAME + " " + suggestion.LAST_NAME}
              </SuggestionItem>
            );
          })}
        </Suggestions>
      );
    }

    return (
      <>
        <SearchBoxContainer>
          <SearchBox type="text" placeholder={this.props.placeholder} onChange={this.onChange} value={userInput} />
          {suggestionsListComponent}
        </SearchBoxContainer>
      </>
    );
  }
}

export default AutocompleteSearch;
