import React, { useState } from 'react';
import { EMPTY_PLAYOFF_TEAM_NAME, ROUND_NAMES } from '../../constants/constants';
import PlayoffMatchup from './PlayoffMatchup';


const INDEXES1 = [0, 7, 4, 2, 3, 5, 6, 1];
const INDEXES2 = [0, 3, 2, 1, 1, 2, 3, 0];
const INDEXES3 = [0, 1, 1, 0, 0, 1, 1, 0]; 


const PlayoffGrid = (props) => {
    const [showingGames, setShowingGames] = useState(false);

    let teams = props.teamStandings;
    let playoffStandings = props.playoffStandings;
    let playoffMatchups = props.playoffMatchups;
    let selectedSeason = props.selectedSeason;

    if (teams.length === 0 || !playoffStandings) {
        return null;
    }

    let playoffTeams = [new Array(8), new Array(4), new Array(2)];

    for(let i = 0; i < 8; i += 1) {
        let index1 = INDEXES1[i];

        let name = teams[i] ? teams[i].NAME : EMPTY_PLAYOFF_TEAM_NAME;
        let teamId = teams[i] ? teams[i].ID : 0;
        let wins = teams[i] ? playoffStandings[0][name] : 0;
        let games = teams[i] ? playoffMatchups[0][name] : 0;


        playoffTeams[0][index1] = {
            NAME: name,
            TEAM_ID: teamId,
            WINS: wins,
            games: games,
        };

        if (playoffTeams[0][index1].WINS === 2) {
            let index2 = INDEXES2[i];
            wins = playoffStandings[1][name];
            games = playoffMatchups[1][name];
            playoffTeams[1][index2] = {
                NAME: name,
                TEAM_ID: teamId,
                WINS: wins,
                games: games,
            }

            if (playoffTeams[1][index2].WINS === 2) {
                let index3 = INDEXES3[i];
                wins = playoffStandings[2][name];
                games = playoffMatchups[2][name];
                playoffTeams[2][index3] = {
                    NAME: name,
                    TEAM_ID: teamId,
                    WINS: wins,
                    games: games,
                }
            }
        }
    }

    return (
        <div>
            <div className="container-playoff">
                <div className="round round-one">
                    <div className="round-details">{ROUND_NAMES[1]}</div>
                    <PlayoffMatchup 
                        team1={playoffTeams[0][0]}
                        team2={playoffTeams[0][1]}
                        showingGames={selectedSeason.ID < 5}
                    />  
                    <PlayoffMatchup 
                        team1={playoffTeams[0][2]}
                        team2={playoffTeams[0][3]}
                        showingGames={true}
                    />  
                    <PlayoffMatchup 
                        team1={playoffTeams[0][4]}
                        team2={playoffTeams[0][5]}
                        showingGames={true}
                    />  
                    <PlayoffMatchup 
                        team1={playoffTeams[0][6]}
                        team2={playoffTeams[0][7]}
                        showingGames={selectedSeason.ID < 5}
                    />                                        
                </div>

                <div className="round round-two">
                    <div className="round-details">{ROUND_NAMES[2]}</div>         
                    <PlayoffMatchup 
                        team1={playoffTeams[1][0]}
                        team2={playoffTeams[1][1]}
                        showingGames={true}
                    />    
                    <PlayoffMatchup 
                        team1={playoffTeams[1][2]}
                        team2={playoffTeams[1][3]}
                        showingGames={true}
                    />   
                            
                </div>
                
                <div className="round round-three">
                    <div className="round-details">{ROUND_NAMES[3]}</div>         
                    <PlayoffMatchup 
                        team1={playoffTeams[2][0]}
                        team2={playoffTeams[2][1]}
                        showingGames={true}
                    />                                
                </div> 
            </div> 
        </div>
    );
};


export default PlayoffGrid