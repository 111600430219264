import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import PlayoffGrid from "../../components/Playoff/PlayoffGrid";
import { getQueryParams, isGameFinished, sortTeams } from "../../constants/utils.js";
import SeasonSelect from "../../components/utils/SeasonSelect.jsx";
import "./Playoff.css";
import NotificationMessage from "../../components/utils/NotificationMessage.jsx";
import { PLAYOFFS_PANDEMIC_MESSAGE } from "../../constants/constants.js";

class Playoff extends Component {
  state = {
    seasons: undefined,
    selectedSeason: undefined,

    teams: {},
    seasonGames: [],
    teamStandings: [],

    playoffStandings: undefined,
    playoffMatchups: undefined,
  };

  componentDidMount = () => {
    this.getSeasons();
  };

  getSeasons = async () => {
    return await axiosInstance.get("/seasons").then((result) => {
      let seasons = result.data;
      seasons.sort((a, b) => b.YEAR - a.YEAR);
      const selectedSeasonYear = parseInt(getQueryParams().get("season"));
      let selectedSeason = seasons.find((season) => season.YEAR === selectedSeasonYear) || seasons[0];
      this.setState({ selectedSeason, seasons });
      this.getTeams(selectedSeason);
      this.getTeamGames(selectedSeason);
      this.getPlayoffStandings(selectedSeason);
    });
  };

  getTeams = (selectedSeason) => {
    axiosInstance.get("/seasons/" + selectedSeason.ID + "/teams").then((result) => {
      let activeTeams = result.data;
      let teams = {};
      activeTeams.forEach((team) => {
        teams[team.NAME] = {
          ID: team.ID,
          ACTIVE: team.ACTIVE,
        };
      });
      this.setState({ teams }, this.getTeamStandings);
    });
  };

  getTeamGames = (selectedSeason) => {
    axiosInstance.get("/seasons/" + selectedSeason.ID + "/games").then((result) => {
      let games = result.data;
      let seasonGames = games.filter((game) => !game.PLAYOFF_GAME && isGameFinished(game));
      this.setState({ seasonGames }, this.getTeamStandings);
    });
  };

  getPlayoffStandings = (selectedSeason) => {
    if (!selectedSeason.ID) {
      return;
    }
    axiosInstance.get("/seasons/" + selectedSeason.ID + "/games/playoff").then((result) => {
      let playoffGames = result.data;
      playoffGames.sort((a, b) => a.GAME_ROUND - b.GAME_ROUND)
      playoffGames = playoffGames.filter((game) => game.HOME_SCORE !== null && game.AWAY_SCORE !== null);
      let playoffStandings = [{}, {}, {}];
      let playoffMatchups = [{}, {}, {}];

      playoffGames.forEach((game) => {
        let round = parseInt(game.GAME_ROUND) - 1;
        let winner = game.HOME_SCORE > game.AWAY_SCORE ? game.HOME_TEAM : game.AWAY_TEAM;

        if (!playoffMatchups[round][game.AWAY_TEAM] && !playoffMatchups[round][game.HOME_TEAM]) {
          playoffMatchups[round][game.AWAY_TEAM] = [game];
          playoffMatchups[round][game.HOME_TEAM] = [game];
        } else {
          playoffMatchups[round][game.AWAY_TEAM].push(game);
          playoffMatchups[round][game.HOME_TEAM].push(game);
        }

        if (round === 0) {
          playoffStandings[round][winner] = (playoffStandings[round][winner] || 0) + 1;
        } else if (playoffStandings[round][winner] >= 0) {
          playoffStandings[round][winner] += 1;
        }
        if (playoffStandings[round][winner] === 2 && round < 2) {
          playoffStandings[round + 1][winner] = 0;
        }
      });

      this.setState({ playoffStandings, playoffMatchups });
    });
  };

  getTeamStandings = () => {
    let teams = this.state.teams;
    let games = this.state.seasonGames;
    teams = sortTeams(teams, games);
    this.setState({ teamStandings: teams });
  };

  handleSeasonSelect = (e) => {
    let seasons = this.state.seasons;
    let selectedSeason = seasons.find((season) => season.ID === parseInt(e.target.value));
    this.setState({ selectedSeason });
    this.getTeams(selectedSeason);
    this.getTeamGames(selectedSeason);
    this.getPlayoffStandings(selectedSeason);
  };

  render = () => {
    if (!this.state.seasons) {
      return null;
    }
    return (
      <div>
        {this.state.selectedSeason.YEAR === 2019 &&
        <NotificationMessage message={PLAYOFFS_PANDEMIC_MESSAGE}/>}
        <h1 className="text-center">Playoffs</h1>
        <div className="container-space-evenly">
          <div />
          <div />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SeasonSelect
              handleSeasonSelect={this.handleSeasonSelect}
              selectedSeason={this.state.selectedSeason}
              seasons={this.state.seasons}
            />
          </div>
        </div>

        <PlayoffGrid
          teamStandings={this.state.teamStandings}
          playoffStandings={this.state.playoffStandings}
          playoffMatchups={this.state.playoffMatchups}
          selectedSeason={this.state.selectedSeason}
        />
      </div>
    );
  };
}

export default Playoff;
