import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import {
  calculateFgRebPercentage,
  getQueryParams,
  initialSortStatsTable,
  sortStatsTable,
  updateQuery,
} from "../../constants/utils.js";
import Table from "../../components/Tables/Table.jsx";
import { SearchBox, SearchBoxContainer } from "../../components/utils/SearchBox.jsx";

const DEFAULT_SORT_HEADER = "Player";
const DEFAULT_IS_ASCENDING = true;
const DEFAULT_CONDITION = "50% games played";
const SIXTH_MAN_CONDITION = DEFAULT_CONDITION + " & <50% games started";

const MINIMUM_GAMES_PLAYED = 7;
const MAXIMUM_GAMES_STARTED = 0.5;

class SeasonAwards extends Component {
  state = {
    selectedSeason: { ID: 7, YEAR: 2023},
    playerStats: [],
    filteredPlayerStats: [],
    searchFilter: "",

    pageNumber: 1,
    elementsPerPage: 15,

    sortedHeader: DEFAULT_SORT_HEADER,
    isAscending: DEFAULT_IS_ASCENDING,
    sortMultiplier: -1,

    showTotals: false,
    condition: DEFAULT_CONDITION,
    filterFlag: false,
  };

  componentDidMount = () => {
    const queryParams = getQueryParams();
    const pageNumber = parseInt(queryParams.get("pageRegular")) || 1;
    const sortedHeader = queryParams.get("sortRegular") || DEFAULT_SORT_HEADER;
    const isAscending = queryParams.get("ascRegular") ? queryParams.get("ascRegular") === "true" : DEFAULT_IS_ASCENDING;
    
    this.setState({ pageNumber, sortedHeader, isAscending },
        () => { this.playerSeasonStatsTotal() }
    );
  };

  playerSeasonStatsTotal = () => {
    let playersAllSeasonStats = [];
    axiosInstance.get("/seasons/" + this.state.selectedSeason.ID + "/totalPlayerStats").then((res) => {
      let seasonStats = res.data;
			seasonStats = seasonStats.filter((stat) => stat.GP >= MINIMUM_GAMES_PLAYED);
      seasonStats.forEach((playerSeasonStats) => {
        if (playerSeasonStats.LAST_NAME !== null) {
          let statsAvg = this.getTotalStatsAvg(playerSeasonStats, playerSeasonStats.GP);
          let playerStats = this.formatPlayerStat(statsAvg, playerSeasonStats.GP);
        	playersAllSeasonStats.push(playerStats);
        }
      });


      const [sortedStats, sortMultiplier] = initialSortStatsTable(
        playersAllSeasonStats,
        this.state.sortedHeader,
        this.state.isAscending
      );

      let filteredPlayerStats = []
      if (this.state.filterFlag) {
        let stats = sortedStats;
        filteredPlayerStats = stats.filter((stat) => parseFloat(stat.stat.STARTER) < MAXIMUM_GAMES_STARTED * (this.state.showTotals ? stat.stat.GP : 1));
      } else {
        filteredPlayerStats = sortedStats;
      }
      console.log(filteredPlayerStats)

      this.setState({
        playerStats: sortedStats,
        filteredPlayerStats,
        sortMultiplier,
      });
    });
  };

  getTotalStatsAvg = (statsTotal, gamesPlayed) => {
    let statsAvg = {};
    Object.keys(statsTotal).forEach((key) => {
      if (
        key !== "FIRST_NAME" &&
        key !== "LAST_NAME" &&
        key !== "PLAYER_ID" &&
        key !== "SEASON_ID" &&
        key !== "TEAM_ID" &&
        key !== "TEAM_NAME" &&
        key !== "ID" &&
        key !== "GP" &&
        key !== "IS_PLAYOFF"
      ) {
        statsAvg[key] = (gamesPlayed === 0 || this.state.showTotals) ? statsTotal[key] : statsTotal[key] / gamesPlayed;
      } else {
        statsAvg[key] = statsTotal[key];
      }
    });
    return statsAvg;
  };

  formatPlayerStat = (statsAvg, gamesPlayed) => {
    let playerStats = {};
    playerStats.player = {};
    playerStats.player.ID = statsAvg.PLAYER_ID;
    playerStats.player.FIRST_NAME = statsAvg.FIRST_NAME;
    playerStats.player.LAST_NAME = statsAvg.LAST_NAME;
    playerStats.player.JERSEY_NUMBER = statsAvg.JERSEY_NUMBER;
    playerStats.team = {};
    playerStats.team.ID = statsAvg.TEAM_ID;
    playerStats.team.NAME = statsAvg.TEAM_NAME;
    playerStats.gamesPlayed = gamesPlayed;
    playerStats.stat = statsAvg;
    calculateFgRebPercentage(playerStats.stat);
    return playerStats;
  };

  handleRegularPageChange = (value) => {
    this.setState({
      pageNumber: value,
    });
    updateQuery({ pageRegular: value });
  };


  handleHeaderClick = (e) => {
    let [sortedStats, sortedHeader, sortMultiplier, isAscending] = sortStatsTable(
      e,
      this.state.filteredPlayerStats,
      this.state.sortMultiplier,
      this.state.sortedHeader
    );
    updateQuery({ sortRegular: sortedHeader, ascRegular: isAscending });
    this.setState({
      filteredPlayerStats: sortedStats,
      sortMultiplier,
      sortedHeader,
      isAscending,
    });
  };

  handleFilterChange = (e) => {
    e.preventDefault();
    this.setState({ searchFilter: e.target.value });
  };

  applySearchFilter = (playerStats) => {
    if (playerStats !== null) {
      return playerStats.filter(
        (ps) =>
          (ps.player.FIRST_NAME &&
            ps.player.LAST_NAME &&
            (ps.player.FIRST_NAME + " " + ps.player.LAST_NAME)
              .toLowerCase()
              .indexOf(this.state.searchFilter.toLowerCase()) !== -1) ||
          (ps.team.NAME && ps.team.NAME.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) !== -1)
      );
    } else {
      return null;
    }
  };

  handleTotals = () => {
    this.setState({
      showTotals: !this.state.showTotals}, () => this.playerSeasonStatsTotal()
    ); 
  };

  render = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <h1 style={{ justifySelf: "center" }}>Regular Season Awards Voting</h1>
        <div className="container-space-evenly">
          <div className="display-750"></div>
          <div className="container-buttons">
            <u>
              <p onClick={this.handleTotals}> {this.state.showTotals ? "Show Averages" : "Show Totals"}</p>
            </u>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchBoxContainer>
              <SearchBox type="text" placeholder="Find player..." onChange={(e) => this.handleFilterChange(e)} />
            </SearchBoxContainer>
          </div>
        </div>
        <Table
          title={`Eligible Players (${this.state.filterFlag ? SIXTH_MAN_CONDITION : DEFAULT_CONDITION})`}
          hasPagination={true}
          handlePageChange={this.handleRegularPageChange}
          elementsPerPage={this.state.elementsPerPage}
          currentPage={this.state.pageNumber}
          showJerseyNumber={false}
          showSeason={false}
          showPlayerName={true}
          showTeam={true}
          showGamesPlayed={true}
          shouldRoundStats={!this.state.showTotals}
          rowsInfo={this.applySearchFilter(this.state.filteredPlayerStats)}
          showRowsTotal={false}
          sortedHeader={this.state.sortedHeader}
          isAscending={this.state.isAscending}
          handleHeaderClick={this.handleHeaderClick}
        />
      </div>
    );
  };
}

export default SeasonAwards;
