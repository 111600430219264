import React from "react";
import Bar from "../../components/Navigation/Bar";
import Burger from "../../components/Navigation/Burger";
import logo from "../../static/images/logo_v2.png";
import allstarLogo from "../../images/allstar_original_logo_nobackground.png";
import axiosInstance from "../../api/AxiosInstance";

class Navigation extends React.Component {
  state = {
    players: [],
    teams: [],
  };

  componentDidMount = () => {
    this.getPlayers();
    this.getTeams();
  };

  getPlayers = () => {
    axiosInstance.get("/players").then((res) => {
      this.setState({
        players: res.data,
      });
    });
  };

  getTeams = () => {
    axiosInstance.get("/seasons").then((res) => {
      let seasons = res.data;
      seasons.sort((a, b) => b.YEAR - a.YEAR);
      axiosInstance.get("/seasons/" + seasons[0].ID + "/teams").then((res) => {
        let teams = res.data;
        this.setState({
          teams,
        });
      });
    });
  };

  handleSelect = (e, option) => {
    window.location("/players/" + option.ID);
  };

  render() {
    const isAllstar = false // (window.location.pathname.includes("allstar")) 

    return (
      <>
        <nav>
          <a className={`${isAllstar ? "allstar-" : ""}logo link-logo`} {... isAllstar ? {href: "/allstar"} : {href: "/"}} style={{cursor: "pointer"}}>
            <img className={`${isAllstar ? "allstar-" : ""}logo img-logo`} src={isAllstar ? allstarLogo : logo} alt="Logo" />
          </a>
          <Bar handleSelect={this.handleSelect} players={this.state.players} teams={this.state.teams} />
          <Burger handleSelect={this.handleSelect} players={this.state.players} teams={this.state.teams} />
        </nav>
      </>
    );
  }
}

export default Navigation;
