import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import TeamInfo from "../../components/TeamInfo/TeamInfo.jsx";
import SeasonSelect from "../../components/utils/SeasonSelect.jsx";
import { getSeasonYearFromQuery } from "../../constants/utils.js";

class TeamsList extends Component {
  state = {
    seasons: [],
    teams: [],
    selectedSeason: undefined,
  };

  componentDidMount = async () => {
    await this.getSeasons();
    this.getTeamsBySeason();
  };

  getTeamsBySeason = () => {
    const { selectedSeason } = this.state;
    axiosInstance
      .get("/seasons/" + selectedSeason.ID + "/teams")
      .then((res) => {
        const teams = res.data;
        this.setState({ teams });
      });
  };

  getSeasons = () => {
    return axiosInstance.get("/seasons").then((res) => {
      const seasons = res.data.sort((a, b) => b.YEAR - a.YEAR);
      const selectedSeason = seasons.find((season) => season.YEAR === parseInt(getSeasonYearFromQuery())) || seasons[0];
      this.setState({ seasons, selectedSeason });
    });
  };

  handleSeasonSelect = (e) => {
    const { seasons } = this.state;
    const selectedSeason = seasons.find((season) => season.ID === parseInt(e.target.value));
    this.setState({ selectedSeason }, () => {
      this.getTeamsBySeason();
    });
  };

  render = () => {
    const { teams, seasons, selectedSeason } = this.state;

    return (
      <div>
        <h1 className="text-center">Teams</h1>
        <div className="container-space-evenly">
          <div />
          <div />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SeasonSelect
              style={{ textAlign: "center" }}
              seasons={seasons}
              selectedSeason={selectedSeason}
              handleSeasonSelect={this.handleSeasonSelect}
            />
          </div>
        </div>
        <div className="container-teams-info">
          <ul className="teams-info">
            {teams.map((team, key) => (
              <TeamInfo key={key} team={team} season={selectedSeason} />
            ))}
          </ul>
        </div>
      </div>
    );
  };
}

export default TeamsList;
