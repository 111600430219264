import React from "react";
import { EMPTY_PLAYOFF_TEAM_NAME, FINALS_GAME_IDS, ROUND_NAMES, THIRD_PLACE_GAME_IDS } from "../../constants/constants";
import { formatDate, isGameRescheduled } from "../../constants/utils";

/**
 * Functional component that returns a list item representing the game given as a parameter.
 * Based on the paramateres isPlayoff and isUpcomming, the game shows different information.
 * 
 * @param props Object containing all the parameters
 * @param props.game object containing game information
 * @param props.isPlayoff boolean indicating weather the game is regular or playoff
 * @param props.isUpcomming boolean indicating weather the game has already been played or is upcomming
 * @param props.fontSize string indicating font size of team links ("small" | "normal")
 * 
 * @returns game as list item
 * 
 */
const Game = (props) => {
  let { game, isPlayoff, isUpcomming, fontSize } = props;
  const homeBold = game.HOME_SCORE > game.AWAY_SCORE ? "bold" : ""
  const awayBold = game.HOME_SCORE < game.AWAY_SCORE ? "bold" : ""
  const isWin = game.RESULT === "WIN";

  const roundDateScore = (
    <>
      {!isPlayoff && (
        <p className="game-date text-center">Round: {game.GAME_ROUND}</p>
      )}
      {isPlayoff && !FINALS_GAME_IDS.some(id => id == game.GAME_ID) && !THIRD_PLACE_GAME_IDS.some(id => id == game.GAME_ID) && (
        <p className="game-date text-center">
          {ROUND_NAMES[game.GAME_ROUND]} - G{game.PLAYOFF_GAME}
        </p>
      )}
      {isPlayoff && THIRD_PLACE_GAME_IDS.some(id => id == game.GAME_ID) && (
        <p className="game-date text-center">
          Third place match
        </p>
      )}
      {isPlayoff && FINALS_GAME_IDS.some(id => id == game.GAME_ID) && (
        <p className="game-date text-center">
          Final
        </p>
      )}
      {!isUpcomming && <div className="container-score">
        {!isUpcomming && !isGameRescheduled(game) && (
          <>
            <div className={`team-score text-center ${homeBold}`}>{game.HOME_SCORE}</div>
            <div className="score-separator text-center">{game.HOME_SCORE || game.AWAY_SCORE ? ":" : ""}</div>
            <div className={`team-score text-center ${awayBold}`}>{game.AWAY_SCORE}</div>
          </>
        )}
      </div>}
      <p className="game-date text-center">{formatDate(game)}</p>
    </>
  );

  return (
    <li className="list-item-game">
      <div className="container-game-info">
        {<div style={{ color: isWin ? "green" : "red", fontWeight: "bold", margin: "10px", fontSize: "2.5rem", minWidth: "1rem" }}>{game.RESULT && game.RESULT[0]}</div>}
        <a className={`team-link ${fontSize}`} href={game.HOME_TEAM_ID !== 0 ? "/teams/" + game.HOME_TEAM_ID : ""}>
          {game.HOME_TEAM ? game.HOME_TEAM : EMPTY_PLAYOFF_TEAM_NAME}
        </a>
        {!isUpcomming && (
          <a href={"/games/" + game.GAME_ID} className="link-date-score">
            {roundDateScore}
          </a>
        )}
        {isUpcomming && <div className="link-date-score">{roundDateScore}</div>}
        <a className={`team-link ${fontSize} ${awayBold}`} href={game.AWAY_TEAM_ID !== 0 ? "/teams/" + game.AWAY_TEAM_ID : ""}>
          {game.AWAY_TEAM ? game.AWAY_TEAM : EMPTY_PLAYOFF_TEAM_NAME}
        </a>
      </div>
    </li>
  );
};

export default Game;
