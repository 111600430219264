import React from "react";
import { ALL_STARS_STATS_TABLE_HEADERS_WIDTH, ALL_STARS_STATS_TABLE_HEADERS } from "../../constants/constants";
import AllStarRow from "../TableRows/AllStarRow";

import ARROW_UP from "../../assets/images/arrow_up_black.png";
import ARROW_DOWN from "../../assets/images/arrow_down_black.png";


/**
 * Functional component that returns a table based on the given parameters.
 *
 * @param props Object containing all the parameters
 * @param props.title table title
 * @param props.hasPagination boolean indicating weather the table is paginated
 * @param props.handlePageChange function called when new page is clicked
 * @param props.currentPage current page number if the table is paginated
 * @param props.votingResults all star voting results
 * @param props.rowsInfo array of objects containing row information (keys: season, player, isStarter, team, stat)
 * @param props.sortedHeader string (table header) indicating by which value the the table is sorted by
 * @param props.isAscending boolean indicating if the sorting is in ascending order
 * @param props.handleHeaderClick function called when header is clicked
 * @param props.teams array of teams
 * @param props.handleVote function to vote for or remove All Star vote for given
 * @param props.isBlocked boolean to indicate if button for voting should be blocked
 * @param props.activeImgSrc image for active/voted All Star player
 * @param props.inactiveImgSrc image for inactive/unvoted All Star player
 * 
 * @returns table
 */

const AllStarTable = (props) => {
  let {
    hasPagination,
    votingResults,
    rowsInfo,
    sortedHeader,
    isAscending,
    handleHeaderClick,
    teams,
    handleVote,
    isBlocked,
    activeImgSrc,
    inactiveImgSrc,
  } = props;

  if (!rowsInfo) {
    return null;
  }

  const isHeaderSortIndex = (header) => {
    return hasPagination && header === "#";
  };

  let tableHeaders = ALL_STARS_STATS_TABLE_HEADERS.map((header, i) => {
    let arrow = header === sortedHeader ? (isAscending ? ARROW_UP : ARROW_DOWN) : null;
    return (
        <th
            key={"header-" + i}
            style={{
            minWidth: ALL_STARS_STATS_TABLE_HEADERS_WIDTH[i] + "rem",
            zIndex: (header === "Player" ? 1 : 0),
            }}
            onClick={(e) => (isHeaderSortIndex(header) ? {} : handleHeaderClick(e))}
            value={header}
            className={"hover-select-pointer" + (header === "Player" ? " sticky" : "")}
        >
            {header}
            {!isHeaderSortIndex(header) && arrow && (
            <img className="header-sort-arrow" value={header} src={arrow} alt="" />
            )}
        </th>
    );
  });

  let lastPage = teams.length
  let rowsInfoPaginated = rowsInfo;

  const colSpan = tableHeaders.filter((header) => header !== null).length;

  return (
    <>
      <div className="container-table">
        <table>
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
          <tbody>
            {rowsInfoPaginated.length === 0 && (
              <tr>
                <td colSpan={colSpan}>No data available</td>
              </tr>
            )}
            {rowsInfoPaginated.map((rowInfo, i) => {
              let rowIndex =
                (isAscending && sortedHeader !== "Player" && sortedHeader !== "Team") ||
                (!isAscending && (sortedHeader === "Player" || sortedHeader === "Team"))
                  ? rowsInfo.length
                  : 0;
              return (
                <AllStarRow
                  key={i}
                  rowKey={"row-" + i}
                  index={rowIndex}
                  gamesPlayed={rowInfo.gamesPlayed}
                  player={rowInfo.player}
                  team={rowInfo.team}
                  stats={rowInfo.stat}
                  isVoted={votingResults.map((result) => result.VOTEDPLAYERID).includes(rowInfo.player.ID)}
                  isBlocked={isBlocked}
                  handleVote={handleVote}
                  activeImgSrc={activeImgSrc}
                  inactiveImgSrc={inactiveImgSrc}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AllStarTable;
