import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";

import ALL_STAR_ORIGINAL from "../../images/allstar_original_logo_nobackground.png";
import ALL_STAR_DIS from "../../images/allstar_disabled_logo_nobackground.png";

import VotingResultsTable from "../../components/Tables/VotingResultsTable.jsx";

class VotingResultsPage extends Component {
  state = {
    votingResults: [],
    voters: [],
  }

  componentDidMount = () => {
    this.getVoters().then(() => {
      this.getVotingResults();
    });
  };

  getVoters = () => {
    return axiosInstance.get("/voters").then((response) => {
      const voters = response.data;
      this.setState({ voters });
    });
  };

  getVotingResults = () => {
    return axiosInstance.get("/votes").then((response) => {
      const votingResults = response.data;
      console.log(votingResults);
      this.setState({ votingResults });
    });
  };

  render = () => {

    const { votingResults } = this.state;

    return (
      <React.Fragment>
        <div>
          <h1 className="text-center" style={{ marginTop: "10rem" }}>All Stars Selections</h1>
          <VotingResultsTable
            index={1}
            votingResults={votingResults}
            handleVote={() => {}}
            isBlocked={true}
            activeImgSrc={ALL_STAR_ORIGINAL}
            inactiveImgSrc={ALL_STAR_DIS}
            showVotes={true}
          />
        </div>
      </React.Fragment>
    )
  }

}

export default VotingResultsPage;