import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance";
import Table from "../../components/Tables/Table";
import { calculateFgRebPercentage, formatDate, getQueryParams, initialSortStatsTable, sortStatsTable, updateQuery } from "../../constants/utils";
import styled from "styled-components";
import YoutubeIFrame from "../../components/utils/YoutubeIFrame";

const DEFAULT_SORT_HEADER = "#";
const DEFAULT_IS_ASCENDING = true;

const HeaderContainer = styled.div`
  margin: 0 auto;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const HOME = "home";
const AWAY = "away";

class GameDetails extends Component {
  state = {
    GAME_ID: this.props.gameId,
    game: undefined,
    gameStats: undefined,

    homeTeam: undefined,
    homeStats: undefined,
    homeTeamStats: undefined,
    homeSortMultiplier: -1,
    homeSortedHeader: DEFAULT_SORT_HEADER,
    homeIsAscending: DEFAULT_IS_ASCENDING,

    awayTeam: undefined,
    awayStats: undefined,
    awayTeamStats: undefined,
    awaySortMultiplier: -1,
    awaySortedHeader: DEFAULT_SORT_HEADER,
    awayIsAscending: DEFAULT_IS_ASCENDING,
  };

  componentDidMount = () => {
    const queryParams = getQueryParams();
    const homeSortedHeader = queryParams.get("sortHome") || DEFAULT_SORT_HEADER;
    const homeIsAscending = queryParams.get("ascHome") ? queryParams.get("ascHome") === "true" : DEFAULT_IS_ASCENDING;
    const awaySortedHeader = queryParams.get("sortAway") || DEFAULT_SORT_HEADER;
    const awayIsAscending = queryParams.get("ascAway") ? queryParams.get("ascAway") === "true" : DEFAULT_IS_ASCENDING;
    this.setState({
      homeSortedHeader,
      homeIsAscending,
      awaySortedHeader,
      awayIsAscending,
    });
    this.getGameStats();
    this.getGame().then(() => {
      this.getTeam(HOME);
      this.getTeam(AWAY);
    });
  };

  getGame = async () => {
    let id = this.state.GAME_ID;
    return await axiosInstance.get("/games/" + id).then((result) => {
      let game = result.data;
      this.setState({ game });
    });
  };

  getTeam = (type) => {
    let teamId = type === HOME ? this.state.game.HOME_TEAM : this.state.game.AWAY_TEAM;
    axiosInstance.get("/teams/" + teamId).then((result) => {
      let team = result.data;
      if (type === HOME) {
        this.setState({ homeTeam: team });
      } else if (type === AWAY) {
        this.setState({ awayTeam: team });
      }
      this.fillTeamGameStats();
    });
  };

  getGameStats = () => {
    axiosInstance.get("/games/" + this.state.GAME_ID + "/gameStats").then((result) => {
      let gameStats = result.data;
      this.setState({ gameStats });
      this.fillTeamGameStats();
    });
  };

  createStatRow = (gameStat) => {
    let statRow = {};
    statRow.player = {};
    statRow.player.ID = gameStat.PLAYER_ID;
    statRow.player.FIRST_NAME = gameStat.FIRST_NAME;
    statRow.player.LAST_NAME = gameStat.LAST_NAME;
    statRow.player.JERSEY_NUMBER = gameStat.JERSEY_NUMBER;

    statRow.stat = gameStat;
    
    return statRow;
  };

  fillTeamGameStats = () => {
    let gameStats = this.state.gameStats;

    let homeTeam = this.state.homeTeam;
    let awayTeam = this.state.awayTeam;

    if (!homeTeam || !awayTeam) {
      return;
    }

    let homeStats = [];
    let awayStats = [];
    gameStats.forEach((gameStat) => {
      let statRow = this.createStatRow(gameStat);
      if (gameStat.TEAM_ID === awayTeam.ID) {
        awayStats.push(statRow);
      } else {
        homeStats.push(statRow);
      }
    });

    const getTotalStats = (teamStats) => {
      let teamStatsTotal = {};
      teamStatsTotal.stat = {};
      teamStats.forEach((stat, i) => {
        Object.keys(stat.stat).forEach((key) => {
          if (!["GAME_ID", "PLAYER_ID", "STARTER"].includes(key)) {
            teamStatsTotal.stat[key] = (teamStatsTotal.stat[key] || 0) + stat.stat[key];
          }
        });
      });
      return teamStatsTotal;
    };

    let homeTeamStats = getTotalStats(homeStats);
    let awayTeamStats = getTotalStats(awayStats);

    homeStats.forEach(stat => calculateFgRebPercentage(stat.stat))
    awayStats.forEach(stat => calculateFgRebPercentage(stat.stat))

    const [homeSortedStats, homeSortMultiplier] = initialSortStatsTable(
      homeStats,
      this.state.homeSortedHeader,
      this.state.homeIsAscending
    );
    const [awaySortedStats, awaySortMultiplier] = initialSortStatsTable(
      awayStats,
      this.state.awaySortedHeader,
      this.state.awayIsAscending
    );
    this.setState({
      homeStats: homeSortedStats,
      homeTeamStats,
      homeSortMultiplier,
      awayStats: awaySortedStats,
      awayTeamStats,
      awaySortMultiplier,
    });
  };

  handleHomeHeaderClick = (e) => {
    let [homeSortedStats, homeSortedHeader, homeSortMultiplier, homeIsAscending] = sortStatsTable(
      e,
      this.state.homeStats,
      this.state.homeSortMultiplier,
      this.state.homeSortedHeader
    );
    updateQuery({ sortHome: homeSortedHeader, ascHome: homeIsAscending });
    this.setState({
      homeStats: homeSortedStats,
      homeSortMultiplier,
      homeSortedHeader,
      homeIsAscending,
    });
  };

  handleAwayHeaderClick = (e) => {
    let [awaySortedStats, awaySortedHeader, awaySortMultiplier, awayIsAscending] = sortStatsTable(
      e,
      this.state.awayStats,
      this.state.awaySortMultiplier,
      this.state.awaySortedHeader
    );
    updateQuery({ sortAway: awaySortedHeader, ascAway: awayIsAscending });
    this.setState({
      awayStats: awaySortedStats,
      awaySortMultiplier,
      awaySortedHeader,
      awayIsAscending,
    });
  };

  render = () => {
    if (!this.state.game || !this.state.homeTeam || !this.state.awayTeam) {
      return <></>;
    }
    let home = this.state.homeTeam.NAME;
    let away = this.state.awayTeam.NAME;
    let game = this.state.game;
    return (
      <div>
        <HeaderContainer>
          <div style={{ width: "40%", padding: "0 1rem" }} className="text-left">
            <h1>
              <a className="text-right" href={"/teams/" + game.HOME_TEAM}>
                {home}
              </a>
            </h1>
          </div>
          <div style={{ width: "20%", minWidth: "12rem" }}>
            <h1 className="text-center">
              {game.HOME_SCORE} : {game.AWAY_SCORE}
            </h1>
          </div>
          <div style={{ width: "40%", padding: "0 1rem" }} className="text-right">
            <h1>
              <a className="text-left" href={"/teams/" + game.AWAY_TEAM}>
                {away}
              </a>
            </h1>
          </div>
        </HeaderContainer>
        <p className="date-played">{`${formatDate(game)}`}</p>

        <p className="recap">{`${game.GAME_RECAP}`}</p>

        {this.state.gameStats.length !== 0 && (
          <React.Fragment>
            <Table
              title={home}
              showJerseyNumber={true}
              stringInsteadOfJerseyNumber={"-"}
              showPlayerName={true}
              stringInsteadOfPlayerName={"Total"}
              shouldRoundStats={false}
              rowsInfo={this.state.homeStats}
              showRowsTotal={true}
              rowsTotal={this.state.homeTeamStats}
              sortedHeader={this.state.homeSortedHeader}
              isAscending={this.state.homeIsAscending}
              handleHeaderClick={(e) => this.handleHomeHeaderClick(e)}
            />
            <Table
              title={away}
              showJerseyNumber={true}
              stringInsteadOfJerseyNumber={"-"}
              showPlayerName={true}
              stringInsteadOfPlayerName={"Total"}
              shouldRoundStats={false}
              rowsInfo={this.state.awayStats}
              showRowsTotal={true}
              rowsTotal={this.state.awayTeamStats}
              sortedHeader={this.state.awaySortedHeader}
              isAscending={this.state.awayIsAscending}
              handleHeaderClick={(e) => this.handleAwayHeaderClick(e)}
            />
            {game.YOUTUBE_LINK && <YoutubeIFrame embedId={game.YOUTUBE_LINK} />}
          </React.Fragment>
        )}
      </div>
    );
  };
}

export default GameDetails;
