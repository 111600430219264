import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import SeasonSelect from "../../components/utils/SeasonSelect";
import {
  calculateFgRebPercentage,
  getQueryParams,
  initialSortStatsTable,
  sortStatsTable,
  updateQuery,
} from "../../constants/utils.js";
import Table from "../../components/Tables/Table.jsx";
import { SearchBox, SearchBoxContainer } from "../../components/utils/SearchBox.jsx";
import history from "../../history";

const DEFAULT_SORT_HEADER = "Player";
const DEFAULT_IS_ASCENDING = true;
const DEFAULT_PER_SEASON = true;

class Statistics extends Component {
  state = {
    seasons: [],
    selectedSeason: undefined,
    players: {},
    playerStats: [],
    playerStatsPlayoff: [],
    searchFilter: "",

    perSeason: DEFAULT_PER_SEASON,
    pageNumber: 1,
    pageNumberPlayoff: 1,
    elementsPerPage: 15,

    sortedHeader: DEFAULT_SORT_HEADER,
    isAscending: DEFAULT_IS_ASCENDING,
    sortMultiplier: -1,

    sortedHeaderPlayoff: DEFAULT_SORT_HEADER,
    isAscendingPlayoff: DEFAULT_IS_ASCENDING,
    sortMultiplierPlayoff: -1,

    showTotals: false,
  };

  componentDidMount = () => {
    const queryParams = getQueryParams();
    const perSeason = queryParams.get("data") && queryParams.get("data") === "allTime" ? false : true;
    const selectedSeasonYear = parseInt(queryParams.get("season"));
    const pageNumber = parseInt(queryParams.get("pageRegular")) || 1;
    const pageNumberPlayoff = parseInt(queryParams.get("pagePlayoffs")) || 1;
    const sortedHeader = queryParams.get("sortRegular") || DEFAULT_SORT_HEADER;
    const isAscending = queryParams.get("ascRegular") ? queryParams.get("ascRegular") === "true" : DEFAULT_IS_ASCENDING;
    const sortedHeaderPlayoff = queryParams.get("sortPlayoffs") || DEFAULT_SORT_HEADER;
    const isAscendingPlayoff = queryParams.get("ascPlayoffs")
      ? queryParams.get("ascPlayoffs") === "true"
      : DEFAULT_IS_ASCENDING;

    axiosInstance.get("/seasons").then((result) => {
      let seasons = result.data;
      seasons.sort((a, b) => b.YEAR - a.YEAR);
      let selectedSeason = seasons.find((season) => season.YEAR === parseInt(selectedSeasonYear)) || seasons[0];
      this.setState(
        {
          seasons,
          selectedSeason,
          perSeason,
          pageNumber,
          pageNumberPlayoff,
          sortedHeader,
          isAscending,
          sortedHeaderPlayoff,
          isAscendingPlayoff,
        },
        () => {
          this.fillTable();
        }
      );
    });
  };

  getSeasonsStats = async (playerId) => {
    let allSeasonStats = {};
    await axiosInstance
      .get("/seasons/" + this.state.selectedSeason.ID + "/players/" + playerId + "/gameStats")
      .then((result) => {
        let seasonStats = result.data;
        let seasonGamesPlayed = seasonStats.length;
        let seasonStatsTotal = this.getStatsTotal(seasonStats);

        let seasonStatsAvg = this.getStatsAvg(seasonStatsTotal, seasonGamesPlayed);

        allSeasonStats.team = {};
        allSeasonStats.gamesPlayed = seasonGamesPlayed;
        allSeasonStats.totals = seasonStatsTotal;
        allSeasonStats.avgs = seasonStatsAvg;
      });
    return allSeasonStats;
  };

  getStatsTotal = (stats) => {
    let statsTotal = {};
    
    stats.forEach((stat) => {
      Object.keys(stat).forEach((key) => {
        if (
          key !== "FIRST_NAME" &&
          key !== "LAST_NAME" &&
          key !== "PLAYER_ID" &&
          key !== "SEASON_ID" &&
          key !== "TEAM_ID" &&
          key !== "TEAM_NAME" &&
          key !== "ID" &&
          key !== "IS_PLAYOFF"
        ) {
          statsTotal[key] = (statsTotal[key] || 0) + stat[key];
        } else {
          statsTotal[key] = stat[key];
        }
      });
    });
    return statsTotal;
  };

  getStatsAvg = (statsTotal, gamesPlayed) => {
    let statsAvg = {};

    Object.keys(statsTotal).forEach((key) => {
      statsAvg[key] = (gamesPlayed === 0 || this.state.showTotals) ? statsTotal[key] : statsTotal[key] / gamesPlayed;
    });
    return statsAvg;
  };

  getTotalStatsAvg = (statsTotal, gamesPlayed) => {
    let statsAvg = {};
    Object.keys(statsTotal).forEach((key) => {
      if (
        key !== "FIRST_NAME" &&
        key !== "LAST_NAME" &&
        key !== "PLAYER_ID" &&
        key !== "SEASON_ID" &&
        key !== "TEAM_ID" &&
        key !== "TEAM_NAME" &&
        key !== "ID" &&
        key !== "GP" &&
        key !== "IS_PLAYOFF"
      ) {
        statsAvg[key] = (gamesPlayed === 0 || this.state.showTotals) ? statsTotal[key] : statsTotal[key] / gamesPlayed;
      } else {
        statsAvg[key] = statsTotal[key];
      }
    });
    return statsAvg;
  };

  handleSeasonSelect = (e) => {
    let selectedSeason = this.state.seasons.find((season) => season.ID === parseInt(e.target.value));
    this.setState(
      {
        selectedSeason,
        /*
        sortedHeader: DEFAULT_SORT_HEADER,
        isAscending: DEFAULT_IS_ASCENDING,
        sortMultiplier: -1,

        sortedHeaderPlayoff: DEFAULT_SORT_HEADER,
        isAscendingPlayoff: DEFAULT_IS_ASCENDING,
        sortMultiplierPlayoff: -1,*/
      },
      () => {
        this.fillTable();
      }
    );
  };

  fillTable = () => {
    if (this.state.perSeason) {
      this.playerSeasonStatsTotal();
    } else {
      this.getCareerStatsTotal();
    }
  };

  playerSeasonStatsTotal = () => {
    let playersAllSeasonStats = [];
    let playersAllSeasonStatsPlayoff = [];
    axiosInstance.get("/seasons/" + this.state.selectedSeason.ID + "/totalPlayerStats").then((res) => {
      let seasonStats = res.data;

      seasonStats.forEach((playerSeasonStats) => {
        if (playerSeasonStats.LAST_NAME !== null) {
          let statsAvg = this.getTotalStatsAvg(playerSeasonStats, playerSeasonStats.GP);
          let playerStats = this.formatPlayerStat(statsAvg, playerSeasonStats.GP);

          if (playerSeasonStats.IS_PLAYOFF) {
            playersAllSeasonStatsPlayoff.push(playerStats);
          } else {
            playersAllSeasonStats.push(playerStats);
          }
        }
      });
      const [sortedStats, sortMultiplier] = initialSortStatsTable(
        playersAllSeasonStats,
        this.state.sortedHeader,
        this.state.isAscending
      );
      const [sortedStatsPlayoff, sortMultiplierPlayoff] = initialSortStatsTable(
        playersAllSeasonStatsPlayoff,
        this.state.sortedHeaderPlayoff,
        this.state.isAscendingPlayoff
      );

      this.setState({
        playerStats: sortedStats,
        sortMultiplier,
        playerStatsPlayoff: sortedStatsPlayoff,
        sortMultiplierPlayoff,
      });
    });
  };

  getCareerStatsTotal = () => {
    let playersAllTimeStats = [];
    let playersAllTimeStatsPlayoff = [];
    axiosInstance.get("/totalPlayerStats").then((res) => {
      let totalStats = res.data;
      totalStats = totalStats.sort((a, b) => a.ID - b.ID);

      let prevId = totalStats[0].ID;
      let playerAllTimeStats = [];
      let playerAllTimeStatsPlayoff = [];
      totalStats.forEach((stat) => {
        if (stat.LAST_NAME !== null) {
          if (prevId === stat.ID) {
            stat.IS_PLAYOFF ? playerAllTimeStatsPlayoff.push(stat) : playerAllTimeStats.push(stat);
          } else {
            prevId = stat.ID;
            let playerTotalStats = this.getStatsTotal(playerAllTimeStats);
            let playerTotalStatsPlayoff = this.getStatsTotal(playerAllTimeStatsPlayoff);

            let playerTotalStatsAvg = this.getTotalStatsAvg(playerTotalStats, playerTotalStats.GP);
            let playerTotalStatsAvgPlayoff = this.getTotalStatsAvg(playerTotalStatsPlayoff, playerTotalStatsPlayoff.GP);

            if (!this.isEmptyObject(playerTotalStatsAvgPlayoff)) {
              let playerStats = this.formatPlayerStat(playerTotalStatsAvgPlayoff, playerTotalStatsPlayoff.GP);
              playersAllTimeStatsPlayoff.push(playerStats);
            }
            if (!this.isEmptyObject(playerTotalStatsAvg)) {
              let playerStats = this.formatPlayerStat(playerTotalStatsAvg, playerTotalStats.GP);
              playersAllTimeStats.push(playerStats);
            }
            playerAllTimeStats = [];
            playerAllTimeStatsPlayoff = [];
            stat.IS_PLAYOFF ? playerAllTimeStatsPlayoff.push(stat) : playerAllTimeStats.push(stat);
          }
        }
      });

      const [sortedStats, sortMultiplier] = initialSortStatsTable(
        playersAllTimeStats,
        this.state.sortedHeader,
        this.state.isAscending
      );
      const [sortedStatsPlayoff, sortMultiplierPlayoff] = initialSortStatsTable(
        playersAllTimeStatsPlayoff,
        this.state.sortedHeaderPlayoff,
        this.state.isAscendingPlayoff
      );
      this.setState({
        playerStats: sortedStats,
        sortMultiplier,
        playerStatsPlayoff: sortedStatsPlayoff,
        sortMultiplierPlayoff,
      });
    });
  };

  isEmptyObject = (obj) => {
    for (var key in obj) {
      return false;
    }
    return true;
  };

  formatPlayerStat = (statsAvg, gamesPlayed) => {
    let playerStats = {};
    playerStats.player = {};
    playerStats.player.ID = statsAvg.PLAYER_ID;
    playerStats.player.FIRST_NAME = statsAvg.FIRST_NAME;
    playerStats.player.LAST_NAME = statsAvg.LAST_NAME;
    playerStats.player.JERSEY_NUMBER = statsAvg.JERSEY_NUMBER;
    playerStats.team = {};
    playerStats.team.ID = statsAvg.TEAM_ID;
    playerStats.team.NAME = statsAvg.TEAM_NAME;
    playerStats.gamesPlayed = gamesPlayed;
    playerStats.stat = statsAvg;
    calculateFgRebPercentage(playerStats.stat);
    return playerStats;
  };

  handleButtonClick = (e, type) => {
    e.preventDefault();
    let isPerSeason = type === "perSeason";
    if (!(isPerSeason === this.state.perSeason)) {
      let selectedSeason = this.state.seasons[0];
      if (isPerSeason) {
        history.push("?data=bySeason");
      } else {
        history.push("?data=allTime");
      }
      this.setState(
        {
          selectedSeason,
          perSeason: isPerSeason,
          pageNumber: 1,
          pageNumberPlayoff: 1,
          playerStats: [],
          playerStatsPlayoff: [],
        },
        () => {
          this.fillTable();
        }
      );
    }
  };

  handleRegularPageChange = (value) => {
    this.setState({
      pageNumber: value,
    });
    updateQuery({ pageRegular: value });
  };

  handlePlayoffPageChange = (value) => {
    this.setState({
      pageNumberPlayoff: value,
    });
    updateQuery({ pagePlayoffs: value });
  };

  handleHeaderClick = (e) => {
    let [sortedStats, sortedHeader, sortMultiplier, isAscending] = sortStatsTable(
      e,
      this.state.playerStats,
      this.state.sortMultiplier,
      this.state.sortedHeader
    );
    updateQuery({ sortRegular: sortedHeader, ascRegular: isAscending });
    this.setState({
      playerStats: sortedStats,
      sortMultiplier,
      sortedHeader,
      isAscending,
    });
  };

  handleHeaderClickPlayoff = (e) => {
    let [sortedStats, sortedHeaderPlayoff, sortMultiplierPlayoff, isAscendingPlayoff] = sortStatsTable(
      e,
      this.state.playerStatsPlayoff,
      this.state.sortMultiplierPlayoff,
      this.state.sortedHeaderPlayoff
    );
    updateQuery({ sortPlayoffs: sortedHeaderPlayoff, ascPlayoffs: isAscendingPlayoff });
    this.setState({
      playerStatsPlayoff: sortedStats,
      sortMultiplierPlayoff,
      sortedHeaderPlayoff,
      isAscendingPlayoff,
    });
  };

  handleFilterChange = (e) => {
    e.preventDefault();
    this.setState({ searchFilter: e.target.value });
  };

  applySearchFilter = (playerStats) => {
    if (playerStats !== null) {
      return playerStats.filter(
        (ps) =>
          (ps.player.FIRST_NAME &&
            ps.player.LAST_NAME &&
            (ps.player.FIRST_NAME + " " + ps.player.LAST_NAME)
              .toLowerCase()
              .indexOf(this.state.searchFilter.toLowerCase()) !== -1) ||
          (ps.team.NAME && ps.team.NAME.toLowerCase().indexOf(this.state.searchFilter.toLowerCase()) !== -1)
      );
    } else {
      return null;
    }
  };

  handleTotals = () => {
    this.setState({
      showTotals: !this.state.showTotals}, () => this.fillTable()
    ); 
  };

  render = () => {
    const seasons = [];

    for (let i = 0; i < this.state.seasons.length; i++) {
      seasons.push(
        <option className="select-seasons option" key={i} value={this.state.seasons[i].ID}>
          {this.state.seasons[i].YEAR}/{parseInt(this.state.seasons[i].YEAR) + 1}
        </option>
      );
    }

    return (
      <div style={{ textAlign: "center" }}>
        <h1 style={{ justifySelf: "center" }}>Statistics</h1>

        <div className="container-space-evenly">
          <div className="display-750"></div>
          <div className="container-buttons">
            <u>
              <p onClick={(e) => this.handleButtonClick(e, "perSeason")}>Season</p>
            </u>
            <u>
              <p onClick={(e) => this.handleButtonClick(e, "allTime")}>All Time</p>
            </u>
            <u>
              <p onClick={this.handleTotals}> {this.state.showTotals ? "Show Averages" : "Show Totals"}</p>
            </u>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SearchBoxContainer>
              <SearchBox type="text" placeholder="Find player..." onChange={(e) => this.handleFilterChange(e)} />
            </SearchBoxContainer>
          </div>
        </div>

        {this.state.perSeason && (
          <div style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
            <SeasonSelect
              style={{ textAlign: "center" }}
              seasons={this.state.seasons}
              selectedSeason={this.state.selectedSeason}
              handleSeasonSelect={this.handleSeasonSelect}
            />
          </div>
        )}

        <Table
          title={"Regular season"}
          hasPagination={true}
          handlePageChange={this.handleRegularPageChange}
          elementsPerPage={this.state.elementsPerPage}
          currentPage={this.state.pageNumber}
          showJerseyNumber={false}
          showSeason={false}
          showPlayerName={true}
          showTeam={this.state.perSeason}
          showGamesPlayed={true}
          shouldRoundStats={!this.state.showTotals}
          rowsInfo={this.applySearchFilter(this.state.playerStats)}
          showRowsTotal={false}
          sortedHeader={this.state.sortedHeader}
          isAscending={this.state.isAscending}
          handleHeaderClick={this.handleHeaderClick}
        />
        {this.state.playerStatsPlayoff.length > 0 && (
          <Table
            title={"Playoffs"}
            hasPagination={true}
            handlePageChange={this.handlePlayoffPageChange}
            elementsPerPage={this.state.elementsPerPage}
            currentPage={this.state.pageNumberPlayoff}
            showJerseyNumber={false}
            showSeason={false}
            showPlayerName={true}
            showTeam={this.state.perSeason}
            showGamesPlayed={true}
            shouldRoundStats={!this.state.showTotals}
            rowsInfo={this.applySearchFilter(this.state.playerStatsPlayoff)}
            showRowsTotal={false}
            sortedHeader={this.state.sortedHeaderPlayoff}
            isAscending={this.state.isAscendingPlayoff}
            handleHeaderClick={this.handleHeaderClickPlayoff}
          />
        )}
      </div>
    );
  };
}

export default Statistics;
