import React from "react";
import StandingsRow from "../TableRows/StandingsRow";
/**
 * Functional component that returns a table based on the given parameters.
 * Displays team standings for season already sorted in parent component
 *
 * @param props Object containing all the parameters
 * @param props.season Object with season information (keys: ID, YEAR)
 * @param props.title table title
 * @param props.teamStandings array of objects containing information about games played (wins and losses) for each team
 * @returns standings table
 */

const StandingsTable = (props) => {
  let teamStandings = props.teamStandings;
  let headers = (
    <tr>
      <th>#</th>
      <th>Team</th>
      <th>Games played</th>
      <th>Wins</th>
      <th>Losses</th>
      <th>+/-</th>
    </tr>
  );

  return (
    teamStandings.length > 0 && (
      <React.Fragment>
        <div className="container-table">
        <h2>{props.title}</h2>
          <table>
            <thead>{headers}</thead>
            <tbody>
              {teamStandings.map((teamStanding, i) => (
                <StandingsRow
                  teamStanding={teamStanding}
                  key={i}
                  index={i + 1}
                  season={props.season}
                />
              ))}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    )
  );
};

export default StandingsTable;
