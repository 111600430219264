import React from "react";
import styled from "styled-components";

const Games = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  left: 0;
  height: ${({ showingGames, gamesPlayed }) => (showingGames ? gamesPlayed * 6 + "rem" : 0)};
  transition: all 0.3s ease;
  width: 100%;
  background-color: white;
  border: ${({ showingGames }) => (showingGames ? "1px solid rgba(0, 0, 0, 0.2)" : "0px solid rgba(0, 0, 0, 0.2)")};
  box-shadow: ${({ showingGames }) => (showingGames ? "0px 4px 16px 4px rgb(0, 0, 0, 0.5)" : "none")};
  z-index: 10;
`;

const PlayoffMatchupGames = (props) => {
  let games = props.games;
  if (!props.games) {
    return null;
  }
  games.sort((a, b) => a.PLAYOFF_GAME - b.PLAYOFF_GAME);

  return (
    <Games showingGames={props.showingGames} gamesPlayed={props.games.length}>
      {games.map((game) => {
        return (
          <a className="game-link text-center" href={"/games/" + game.GAME_ID} key={game.GAME_ID}>
            <div className="container-team-name">{game.HOME_TEAM}</div>
            <div className="container-game-score">
              {game.HOME_SCORE} : {game.AWAY_SCORE}
            </div>
            <div className="container-team-name">{game.AWAY_TEAM}</div>
          </a>
        );
      })}
    </Games>
  );
};

export default PlayoffMatchupGames;
