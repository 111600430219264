import React from "react";
import styled from "styled-components";
import AutocompleteSearch from "../utils/AutocompleteSearch";

const Ul = styled.ul`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    z-index: 99;
    list-style: none;
    flex-flow: column;
    justify-content: space-around;
    background-color: #003d68;
    opacity: 1;

    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 9rem;
    right: 0;
    height: calc(100vh - 20rem);
    width: 100vw;
    padding: 5rem 0;
    transition: transform 0.25s ease-in-out;

    .right-nav-a {
      text-decoration: none;
      font-size: 3rem;
      padding: 1.8rem;
      color: white;
    }
  }
`;

const RightNav = (props) => {
  let { players } = props;

  return (
    <Ul open={props.open}>
      <li>
        <AutocompleteSearch suggestions={players} placeholder="Find player..." />
      </li>
      <li>
        <a className="right-nav-a" href="https://www.tiny.cc/FERBasket_FIBA" target="_blank" rel="noopener noreferrer">
          FIBA
        </a>
      </li>
      {/* <li>
        <a className="right-nav-a" href="/">
          Home
        </a>
      </li> */}
      <li>
        <a className="right-nav-a" href="/statistics">
          Statistics
        </a>
      </li>
      <li>
        <a className="right-nav-a" href="/topPerformances">
          Top Performances
        </a>
      </li>
      <li>
        <a className="right-nav-a" href="/playoff">
          Playoffs
        </a>
      </li>
      <li>
        <a className="right-nav-a" href="/teams">
          Teams Archive
        </a>
      </li>
    </Ul>
  );
};

export default RightNav;
