import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import StandingsTable from "../../components/Tables/StandingsTable.jsx";
import GamesList from "../../components/Games/GamesList.jsx";
import SeasonSelect from "../../components/utils/SeasonSelect.jsx";
import { getQueryParams, isGameFinished, sortTeams, updateQuery, getSeasonYearFromQuery, isGameRescheduled } from "../../constants/utils.js";
import { NUMBER_OF_UPCOMING_GAMES_TO_SHOW } from "../../constants/constants.js";

import CHAMPIONS_2017 from "../../images/champions_2017.png";
import CHAMPIONS_2018 from "../../images/champions_2018.png";
import CHAMPIONS_2019 from "../../images/champions_2019.png";
import CHAMPIONS_2022 from "../../images/champions_2022.png";
import CHAMPIONS_2023 from "../../images/champions_2023.png";
import CHAMPIONS_2024 from "../../images/champions_2024.png";

class Home extends Component {
  state = {
    seasons: [],
    season: undefined, 
    teams: {},
    seasonGames: [],
    teamStandings: [],
    upcomingGames: [],
    seasonRegularGames: [],
    count: 1,
    round: parseInt(getQueryParams().get("round")) || undefined,
  };

  componentDidMount = async () => {
    await this.getSeasons();
    this.loadScreen();
  };

  loadScreen = () => {
    this.getTeams();
    this.getTeamGames();
    this.getUpcomingGames();
    this.getSeasonRegularGames();
  }

  getSeasons = () => {
    return axiosInstance.get("/seasons").then((result) => {
      let seasons = result.data.sort((a, b) => b.YEAR - a.YEAR);
      let season = seasons.find((season) => season.YEAR === parseInt(getSeasonYearFromQuery())) || seasons[0];
      this.setState({ seasons, season });
    });
  };

  getSeasonRegularGames = () => {
    let season = this.state.season;
    axiosInstance.get("/seasons/" + season.ID + "/games/regular").then((res) => {
      let seasonRegularGames = res.data.sort((a, b) => b.GAME_ROUND - a.GAME_ROUND)
      let count = seasonRegularGames[0]?.GAME_ROUND;
      let temp = seasonRegularGames.filter((game) => isGameFinished(game) || isGameRescheduled(game));
      let lastRound = temp[0]?.GAME_ROUND || 1;
      
      this.setState({
        seasonRegularGames,
        count: count,
        round: lastRound,
      });
    });
  };

  getUpcomingGames = () => {
    axiosInstance.get("/upcomingGames").then((result) => {
      let upcomingGames = result.data.slice(0, NUMBER_OF_UPCOMING_GAMES_TO_SHOW);
      this.setState({ upcomingGames });
    });
  };

  getTeams = () => {
    let season = this.state.season;
    axiosInstance.get("/seasons/" + season.ID + "/teams").then((result) => {
      let activeTeams = result.data;
      let teams = {};
      activeTeams.forEach((team) => {
        teams[team.NAME] = {
          ID: team.ID,
          ACTIVE: team.ACTIVE,
        };
      });
      this.setState({ teams }, this.getTeamStandings);
    });
  };

  getTeamGames = () => {
    let season = this.state.season;

    axiosInstance.get("/seasons/" + season.ID + "/games").then((result) => {
      let games = result.data;
      let seasonGames = games.filter((game) => !game.PLAYOFF_GAME && isGameFinished(game));
      this.setState({ seasonGames });
      this.getTeamStandings();
    });
  };

  getTeamStandings = () => {
    let teams = this.state.teams;
    let games = this.state.seasonGames;
    teams = sortTeams(teams, games);
    this.setState({ teamStandings: teams });
  };

  handleRoundChange = (value) => {
    this.setState({
      round: value,
    });
    updateQuery({ round: value });
  };

  handleSeasonSelect = (e) => {
    let season = this.state.seasons.find((s) => s.ID === parseInt(e.target.value));
    this.setState({ season }, () => {
      this.loadScreen();
    });
  }

  render = () => {
    let roundGames = this.state.seasonRegularGames.filter(
      (game) => game.GAME_ROUND === this.state.round
    ).sort((a, b) => new Date(a.DATE_PLAYED) - new Date(b.DATE_PLAYED));

    let { round, count } = this.state;
    return (
      <div>
        {(
          <div className="container-space-evenly">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <SeasonSelect
                style={{ textAlign: "center" }}
                seasons={this.state.seasons}
                selectedSeason={this.state.season}
                handleSeasonSelect={this.handleSeasonSelect}
              />
            </div>
          </div>
        )}
        <div className="grid-container">
          <div className="grid-item">
            <StandingsTable title={"Standings"} season={this.state.season} teamStandings={this.state.teamStandings} />
          </div>
          {this.state.upcomingGames.length > 0 && this.state.season?.ID == 7 && <div className="grid-item">
            <GamesList title="Upcoming games" games={this.state.upcomingGames} isPlayoff={true} isUpcomming={true} fontSize="small" />
          </div>}
          {this.state.season?.ID === 1 && <div className="grid-item">
            <a href='/games/93'><img src={CHAMPIONS_2017} style={{maxWidth: '100%', marginTop: '7rem'}}></img></a>
          </div>}
          {this.state.season?.ID === 2 && <div className="grid-item">
            <a href='/games/177'><img src={CHAMPIONS_2018} style={{maxWidth: '100%', marginTop: '7rem'}}></img></a>
          </div>}
          {this.state.season?.ID === 3 && <div className="grid-item">
            <a href='/games/258'><img src={CHAMPIONS_2019} style={{maxWidth: '100%', marginTop: '7rem'}}></img></a>
          </div>}
          {this.state.season?.ID === 5 && <div className="grid-item">
            <a href='/games/394'><img src={CHAMPIONS_2022} style={{maxWidth: '100%', marginTop: '7rem'}}></img></a>
          </div>}
          {this.state.season?.ID === 6 && <div className="grid-item">
            <a href='/games/453'><img src={CHAMPIONS_2023} style={{maxWidth: '100%', marginTop: '7rem'}}></img></a>
          </div>}
          {this.state.season?.ID === 7 && <div className="grid-item">
            <a href='/games/524'><img src={CHAMPIONS_2024} style={{maxWidth: '100%', marginTop: '7rem'}}></img></a>
          </div>}
        </div>
        
        {roundGames.length > 0 && (
          <div>
            <div className="pagination paginated-rounds" style={{ marginTop: "2rem" }}>
              <p onClick={() => this.handleRoundChange(1)}>&laquo;</p>
              <p onClick={() => this.handleRoundChange(Math.max(round - 1, 1))}>&lt;</p>
              <h2 style={{ margin: "0 1rem" }} className="text-center">
                {"Round " + this.state.round + " matches"}
              </h2>
              <p onClick={() => this.handleRoundChange(Math.min(round + 1, count))}>&gt;</p>
              <p onClick={() => this.handleRoundChange(count)}>&raquo;</p>
            </div>
            <GamesList games={roundGames} isPlayoff={false} isUpcomming={false} fontSize="normal" />
          </div>
          )}
{/*           
          <div style={{display: "flex", justifyContent: "flex-end", marginRight: "5%", minWidth: "40rem"}}>
              <NotificationMessage message={"Show playoff games >>"} href={`/playoff?season=${this.state.season?.YEAR}`}/>
          </div> */}
      </div>
    );
  };
}

export default Home;
