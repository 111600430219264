import React from "react";
import axiosInstance from "../../api/AxiosInstance";

class Footer extends React.Component {
  state = {
    currentSeason: undefined,
    teams: [],
  };

  componentDidMount = () => {
    this.getSeasons();
  };

  getSeasons = async () => {
    return await axiosInstance.get("/seasons").then((result) => {
      let seasons = result.data;
      seasons.sort((a, b) => b.YEAR - a.YEAR);
      this.setState({ currentSeason: seasons[0] });
      this.getActiveTeams(seasons[0]);
    });
  };

  getActiveTeams = (season) => {
    axiosInstance.get("/seasons/" + season.ID + "/activeTeams").then((result) => {
      let teams = result.data;
      teams.sort((a, b) => a.NAME - b.NAME);
      this.setState({ teams });
    });
  };

  render() {
    return (
      <footer>
        <div className="container-grid">
          <div className="container-footer-teams">
            <h3 className="text-center">Active Teams</h3>
            <ul className="footer-teams">
              {this.state.teams.map((team, i) => (
                <li key={i}>
                  <a href={"/teams/" + team.ID}>{team.NAME}</a>
                </li>
              ))}
            </ul>
          </div>

          <ul className="footer-links">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/topPerformances">Top performances</a>
            </li>
            <li>
              <a href="/statistics">Statistics</a>
            </li>
            <li>
              <a href="/playoff">Playoffs</a>
            </li>
            <li>
              <a href="/teams">Teams archive</a>
            </li>
          </ul>

          <div>
            <div className="container-footer-social text-center">
              <h3>Contact</h3>
              <address>dinobajic@gmail.com</address>
            </div>
            <div className="container-footer-social text-center">
              <h3>Follow us on</h3>
              <div className="footer">
                <a href="https://www.facebook.com/FERbasket1" target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
                <a href="https://www.youtube.com/channel/UCCBx16vR0fb2T3sCi3b5eYQ" target="_blank" rel="noopener noreferrer">
                  Youtube
                </a>
                <a href="https://ferbasket.forumcroatian.com" target="_blank" rel="noopener noreferrer">
                  Forum
                </a>
                <a href="http://ksz-zagreb.hr/ferbasket-liga" target="_blank" rel="noopener noreferrer">
                  KSZ
                </a>
              </div>
            </div>
          </div>
        </div>
        <div id="container-copyright">
          <div id="copyright">Copyright &copy; Udruga "PROam Košarkaška liga FERBasket"</div>
        </div>
      </footer>
    );
  }
}

export default Footer;
