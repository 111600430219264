
import AxiosInstance from "./AxiosInstance";
import { ERROR_PAGE_404_PATHNAME, UPPERCASE_KEYS } from "../constants/constants";
import { convertJSONKeysToUppercase } from "../constants/utils";

const interceptor = (history) => {
  AxiosInstance.interceptors.request.use(
    (conf) => {
      return conf;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  AxiosInstance.interceptors.response.use(
    (next) => {
      const { data } = next;
      next.data = convertJSONKeysToUppercase(data, UPPERCASE_KEYS);
      return Promise.resolve(next);
    },
    (error) => {
      const isErrorPage = window.location.pathname === ERROR_PAGE_404_PATHNAME;
      if (!isErrorPage) {
        history.push(ERROR_PAGE_404_PATHNAME);
        history.go();
      }
      return Promise.reject(error);
    }
  );
};
export default {
  interceptor,
};
