import styled from "styled-components";
import SEARCH_ICON from "../../assets/images/search-icon.png";

// const SEARCH_ICON = "/images/search-icon.png";

export const SearchBoxContainer = styled.div`
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #003d68;
  border-radius: 3px;
  max-width: 18rem;
  padding-right: 1rem;
  height: 4rem;
  justify-self: self-end;
`;

export const SearchBox = styled.input`
  box-sizing: border-box;
  background: url(${SEARCH_ICON});
  background-size: 2.5rem 2.5rem;
  background-repeat: no-repeat;
  background-position: right;
  height: 100%;
  width: 100%;
  font-size: 2rem;
  padding: 1rem 3rem 1rem 1rem;
  border: none;
  outline: none;
`;
