import React from "react";

/**
 * Functional component that returns a component based on given parameters,
 * containing information about specific team
 *
 * @param team - contains team information (ID, NAME)
 * @param season - season
 * @returns link to team's page
 */
const TeamInfo = (props) => {
  const { team, season } = props;
  const queryParameter = "?season=" + season.YEAR;

  return (
    <a className="team-link normal" href={"/teams/" + team.ID + queryParameter}>
      {team.NAME}
    </a>
  );
};

export default TeamInfo;
