import React from "react";
import infoIcon from "../../images/info-icon.png";

const NotificationMessage = (props) => {

    const { message, href } = props;

    return (
        <div className="notification-message-container">
            <div className="notification-message">
                <img className="info-button" src={infoIcon} alt=""></img>&#9;&#9;
                {href ? <a href={href}>{message}</a> : message}
            </div>
        </div>
    )
}

export default NotificationMessage; 