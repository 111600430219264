import React from "react";
import { getPlayoffTeams } from "../../constants/utils";

/**
 * Functional component that returns row in a table based on the given parameters.
 *
 * @param props Object containing all the parameters
 * @param props.index Integer with team standing
 * @param props.season Object with season information (keys: ID, YEAR)
 * @param props.teamStanding Object with team information and games played (wins/losses)
 * 				(keys: teamId, teamName, teamWins, teamLosses, teamPlusMinus)
 *
 * @returns standings table row
 */

const StandingsRow = (props) => {
  let { index, season, teamStanding } = props;
  let { ID, NAME, W, L, PLUSMINUS } = teamStanding;
  
  let numOfTeams = getPlayoffTeams(season.ID);
  let style = numOfTeams === index ? { borderBottom: "solid", borderWidth: "0.5px", color: "#003d68" } : {}
  
  return (
    <tr style={style}>
      <td>{index}.</td>
      <td>
        <a href={"/teams/" + ID}>{NAME}</a>
      </td>
      <td>{W + L}</td>
      <td>{W}</td>
      <td>{L}</td>
      <td>
        {PLUSMINUS > 0 && "+"}
        {PLUSMINUS}
      </td>
    </tr>
  );
};

export default StandingsRow;
