import React from "react";
import Game from "./Game";

/**
 * Functional component that returns a list of games.
 * Based on the paramateres isPlayoff and isUpcomming, different information will be displayed
 *
 * @param props Object containing all the parameters
 * @param props.games array of objects containing game information
 * @param props.isPlayoff boolean indicating weather the game is regular or playoff
 * @param props.isUpcomming boolean indicating weather the game has already been played or is upcomming
 * 
 * @returns List of games
 */
const GamesList = (props) => {
  let { title, games, isPlayoff, isUpcomming, fontSize } = props;

  return (
    <div className="container-ul">
      {title && <h2>{title}</h2>}
      {games.length > 0 && (
        <ul className="list-games">
          {games.map((game, i) => {
            return (
              <Game
                key={i}
                game={game}
                isPlayoff={isPlayoff}
                isUpcomming={isUpcomming}
                fontSize={fontSize}
              />
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default GamesList;
