import React from "react";
import VotingResultsRow from "../TableRows/VotingResultsRow";

/**
 * Functional component that returns a table based on the given parameters.
 * Table containing information about voted players for FERBasket AllStar by single team captain.
 *
 * @param props Object containing all the parameters
 * @param props.index 
 * @param props.title String with title displayed in table header
 * @param props.votingResults Array of objects containing row information
 *          (keys: VOTED_PLAYER_ID, VOTED_TEAM_ID, CONTEST_ID, TEAM_SEED)
 * @param props.handleVote function to vote for or remove All Star vote for given
 * @param props.isBlocked boolean to indicate if button for voting should be blocked
 * @param props.activeImgSrc image for active/voted All Star player
 * @param props.inactiveImgSrc image for inactive/unvoted All Star player
 * @param props.showVotes boolean to indicate if column with number of votes is visible
 * 
 * @returns Voting results table
 */

const VotingResultsTable = (props) => {
  const { index, votingResults, handleVote, isBlocked, activeImgSrc, inactiveImgSrc, showVotes } = props;
  const headers = (
    <tr>
      <th style={{ minWidth: "2.5rem", width: "5%" }}>#</th>
      <th style={{ minWidth: "2.5rem", width: "5%" }}>All Star</th>
      <th style={{ position: "sticky", minWidth: "15rem", width:"15%", zIndex: 1}}>Player Name</th>
      <th style={{ minWidth: "15rem", width:"15%"}}>Team name</th>
      {showVotes && <th style={{minWidth: "3rem", width: "10%"}}>Votes received</th>}
    </tr>
  );

  const emptyTable = (
    <div className="container-table">
      <table>
        <thead>{headers}</thead>
        <tbody>
          <tr>
            <td className="sticky" colSpan={5}>
              Loading selected players...
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  if (votingResults.length === 0) return emptyTable;

  return (
    <React.Fragment key={index}>
      <div className="container-table">
        <table>
          <thead>{headers}.</thead>
          <tbody>
            {votingResults.map((votingResult, i) => (
              <VotingResultsRow
                key={i}
                index={i + 1}
                votingResult={votingResult}
                handleVote={handleVote}
                isVoted={true}
                isBlocked={isBlocked}
                activeImgSrc={activeImgSrc}
                inactiveImgSrc={inactiveImgSrc}
                showVotes={showVotes}
              />
            ))}
          </tbody>
        </table>
      </div>

      <h3 className="text-center">Lineups, team captains and 3pt/dunk contest participants will be announced soon!</h3>
    </React.Fragment>
  );
};

export default VotingResultsTable;
