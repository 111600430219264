import React, { Component } from "react";
import { GENERIC_ERROR_MSG } from "../../constants/constants";
import gif from "../../assets/error-page.gif";
import styled from "styled-components";

const Header = styled.div`
  text-align: center;
  font-size: 6rem;
  font-weight: 300;
  margin-bottom: 1.4rem;

  @media(max-width: 768px){
    margin-top: 4rem;
  }
`;

const ErrorText = styled.div`
  text-align: center;
  font-size: 2.4rem;
  font-weight: 300;
  margin-bottom: 5rem;
`;

const ErrorGifWrapper = styled.div`
  text-align: center;
  img {
    max-width: 90%;
  }
`;

class ErrorPage extends Component {
  render() {
    return (
      <>
        <a style={{ fontSize: "2rem" }} href={"/"}>
          {"<"} Back to Home
        </a>
        <Header>404 error</Header>
        <ErrorText>{GENERIC_ERROR_MSG}</ErrorText>
        <ErrorGifWrapper>
          <img src={gif} alt="loading..." />
        </ErrorGifWrapper>
      </>
    );
  }
}

export default ErrorPage;
