import React, { useState } from "react";
import styled from "styled-components";

const DropdownWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  font-size: 2.5rem;
  font-weight: 300;
  color: white;
  padding: 1rem;
  padding-right: 2.5rem;
  text-align: left;
  cursor: pointer;
  z-index: 1000;
`;

const DropdownBox = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s ease;
  height: ${({ isOpened, items }) => (isOpened ? items.length * 4.5 + "rem" : 0)};
  width: 25rem;
  background-color: #003d68;
  position: absolute;
  top: 5.5rem;
  left: 0;
  box-shadow: ${({ isOpened }) => (isOpened ? "0px 4px 16px 4px rgb(0, 0, 0, 0.5)" : "none")};
  border-radius: 2px;
`;

const DropdownItem = styled.a`
  &,
  &:visited,
  &:target,
  &:active {
    box-sizing: border-box;
    height: 4.5rem;
    font-size: 2rem;
    padding-left: 1.8rem;
    border-bottom: 1px solid rgb(255, 255, 255, 0.25);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const DropdownArrowWrapper = styled.div`
  position: absolute;
  right: 0;
  top: calc(50% - 0.5rem);

  > div {
    position: relative;
    height: 1rem;
    width: 1rem;
  }
  > div::before,
  > div::after {
    content: "";
    position: absolute;
    bottom: 0px;
    width: 0.15rem;
    background-color: white;
    height: 100%;
    transition: all 0.3s;
  }
  > div::before {
    left: 1px;
    transform: ${({ isOpened }) => (isOpened ? "rotate(-45deg)" : "rotate(45deg)")};
  }
  > div::after {
    right: 1px;
    transform: ${({ isOpened }) => (isOpened ? "rotate(45deg)" : "rotate(-45deg)")};
  }
`;

const DropdownArrow = ({ isOpened }) => {
  return (
    <DropdownArrowWrapper isOpened={isOpened}>
      <div className="arrow"></div>
    </DropdownArrowWrapper>
  );
};

const Dropdown = (props) => {
  const { text, items, openOnHover } = props;
  const [isOpened, setIsOpened] = useState(false);

  const handleClick = () => {
    setIsOpened(!isOpened);
  };
  const handleMouseEnter = () => {
    if (openOnHover) {
      setIsOpened(true);
    }
  };
  const handleMouseLeave = () => {
    if (openOnHover) {
      setIsOpened(false);
    }
  };

  const dropdownItems = items.map((item, i) => <DropdownItem key={i} href={item.href}>{item.text}</DropdownItem>);

  return (
    <DropdownWrapper onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {text}
      <DropdownBox isOpened={isOpened} items={items}>
        {dropdownItems}
      </DropdownBox>
      <DropdownArrow isOpened={isOpened} />
    </DropdownWrapper>
  );
};

export default Dropdown;
