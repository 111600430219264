import React from 'react';
import { useState } from 'react';
import PlayoffMatchupGames from './PlayoffMatchupGames';

const EMPTY_TEAM = {
    NAME: "",
    TEAM_ID: "",
    WINS: "",
    games: [],
}

const PlayoffMatchup = (props) => {
    const team1 = props.team1 ? props.team1 : EMPTY_TEAM;
    const team2 = props.team2 ? props.team2 : EMPTY_TEAM;

    const [showingGames, setShowingGames] = useState(false);

    return (
        <div>
        <ul className="matchup" onMouseOver={() => setShowingGames(props.showingGames)} 
            onMouseLeave={() => setShowingGames(false)}>
            <li className="team team-top">
                <a className="link-team" href={team1.TEAM_ID !== 0 ? "/teams/" + team1.TEAM_ID : ""}>{team1.NAME}</a>
                <span className="score">{team1.WINS || (team1.NAME && team2.NAME && 0)}</span>
            </li>
            <li className="team team-bottom">
                <a className="link-team" href={team2.TEAM_ID !== 0 ? "/teams/" + team2.TEAM_ID : ""}>{team2.NAME}</a>
                <span className="score">{team2.WINS || (team1.NAME && team2.NAME && 0)}</span>
            </li>
            <PlayoffMatchupGames 
                showingGames={showingGames}
                games={team1.games}
            />
        </ul>
        </div>
    );
};


export default PlayoffMatchup;