import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import SeasonSelect from "../../components/utils/SeasonSelect.jsx";
import Table from "../../components/Tables/Table.jsx";
import {
  calculateFgRebPercentage,
  formatDate,
  getQueryParams,
  getStatsAvg,
  getStatsTotal,
  initialSortStatsTable,
  isGameFinished,
  sortStatsTable,
  updateQuery,
} from "../../constants/utils.js";

const DEFAULT_SORT_HEADER = "Season";
const DEFAULT_IS_ASCENDING = true;

class PlayerProfile extends Component {
  state = {
    PLAYER_ID: this.props.playerId,
    seasons: [],
    selectedSeason: null,

    player: {},
    seasonTeam: {},

    regularSeasonsStats: [],
    regularCareerStats: {},
    regularSortedHeader: DEFAULT_SORT_HEADER,
    regularSortMultiplier: -1,
    regularIsAscending: DEFAULT_IS_ASCENDING,

    playoffSeasonsStats: [],
    playoffCareerStats: {},
    playoffSortedHeader: DEFAULT_SORT_HEADER,
    playoffSortMultiplier: -1,
    playoffIsAscending: DEFAULT_IS_ASCENDING,

    regSeasonGames: [],
    playoffGames: [],
    showTotals: false,
  };

  componentDidMount = () => {
    const queryParams = getQueryParams();
    const regularSortedHeader = queryParams.get("sortRegular") || DEFAULT_SORT_HEADER;
    const regularIsAscending = queryParams.get("ascRegular")
      ? queryParams.get("ascRegular") === "true"
      : DEFAULT_IS_ASCENDING;
    const playoffSortedHeader = queryParams.get("sortPlayoffs") || DEFAULT_SORT_HEADER;
    const playoffIsAscending = queryParams.get("ascPlayoffs")
      ? queryParams.get("ascPlayoffs") === "true"
      : DEFAULT_IS_ASCENDING;

    this.setState({
      regularSortedHeader,
      regularIsAscending,
      playoffSortedHeader,
      playoffIsAscending,
    });
    this.getPlayer();
    this.getPlayerStats();
  };

  getPlayer = () => {
    let id = this.state.PLAYER_ID;
    return axiosInstance.get("/players/" + id).then((result) => {
      let player = result.data;
      this.setState({ player });
    });
  };

  formatStat = (totalStat) => {
    let stat = {};
    stat.team = {};
    stat.team.ID = totalStat.TEAM_ID;
    stat.team.NAME = totalStat.TEAM_NAME;
    stat.season = {};
    stat.season.ID = totalStat.SEASON_ID;
    stat.season.YEAR = totalStat.SEASON_YEAR;
    stat.gamesPlayed = totalStat.GP;
    stat.stat = this.state.showTotals ? totalStat : getStatsAvg(totalStat, stat.gamesPlayed);
    calculateFgRebPercentage(stat.stat);
    return stat;
  };

  getPlayerStats = () => {
    axiosInstance.get("/players/" + this.state.PLAYER_ID + "/totalGameStats").then((result) => {
      const totalStats = result.data;

      let regularCareerStats = getStatsTotal(totalStats.filter((stat) => stat.IS_PLAYOFF === 0));
      regularCareerStats = this.formatStat(regularCareerStats);

      let playoffCareerStats = getStatsTotal(totalStats.filter((stat) => stat.IS_PLAYOFF === 1));
      playoffCareerStats = this.formatStat(playoffCareerStats);

      let regularSeasonsStats = [];
      let playoffSeasonsStats = [];
      let seasonTeam = {};
      let seasons = [];
      totalStats.forEach((totalStat) => {
        let stat = this.formatStat(totalStat);
        let team = { ID: totalStat.TEAM_ID, NAME: totalStat.TEAM_NAME };
        if (!seasonTeam[totalStat.SEASON_ID]) {
          seasonTeam[totalStat.SEASON_ID] = team;
          seasons.push({ ID: totalStat.SEASON_ID, YEAR: totalStat.SEASON_YEAR });
        }
        if (totalStat.IS_PLAYOFF === 0) {
          regularSeasonsStats.push(stat);
        } else {
          playoffSeasonsStats.push(stat);
        }
      });

      seasons.sort((a, b) => b.YEAR - a.YEAR);
      const selectedSeasonYear = parseInt(getQueryParams().get("season"));
      let selectedSeason = seasons.find((season) => season.YEAR === selectedSeasonYear) || seasons[0];
      const [regularSeasonsStatsSorted, regularSortMultiplier] = initialSortStatsTable(
        regularSeasonsStats,
        this.state.regularSortedHeader,
        this.state.regularIsAscending
      );
      const [playoffSeasonsStatsSorted, playoffSortMultiplier] = initialSortStatsTable(
        playoffSeasonsStats,
        this.state.playoffSortedHeader,
        this.state.playoffIsAscending
      );
      this.setState({
        seasons,
        selectedSeason,
        seasonTeam,
        regularSeasonsStats: regularSeasonsStatsSorted,
        regularCareerStats,
        regularSortMultiplier,
        playoffSeasonsStats: playoffSeasonsStatsSorted,
        playoffCareerStats,
        playoffSortMultiplier,
      });
      this.getGames(selectedSeason);
    });
  };

  formatGameStat = (game) => {
    let gameStats = {};
    const isPlayerInHomeTeam = this.state.seasonTeam[this.state.selectedSeason.ID].ID === game.HOME_TEAM_ID;
    gameStats.team = {
      ID: isPlayerInHomeTeam ? game.AWAY_TEAM_ID : game.HOME_TEAM_ID,
      NAME: isPlayerInHomeTeam ? game.AWAY_TEAM : game.HOME_TEAM,
    };
    gameStats.game = {
      ID: game.GAME_ID,
      DATE: formatDate(game) + "h",
    };
    gameStats.stat = game;
    calculateFgRebPercentage(gameStats.stat);
    return gameStats;
  };

  getGames = (season) => {
    if (season) {
      axiosInstance
      .get("/seasons/" + season.ID + "/players/" + this.state.PLAYER_ID + "/gameStats")
      .then((result) => {
        let playerGames = result.data;
        let playoffGames = [];
        let regSeasonGames = [];
        playerGames.forEach((game) => {
          if (isGameFinished(game)) {
            const gameData = this.formatGameStat(game);
            if (game.IS_PLAYOFF) {
              playoffGames.push(gameData);
            } else {
              regSeasonGames.push(gameData);
            }
          }
        });
        this.setState({ regSeasonGames, playoffGames });
      });
    } else {
      this.setState({regSeasonGames: [], playoffGames: []})
    }
  };

  handleSeasonSelect = (e) => {
    let seasons = this.state.seasons;
    let selectedSeason = seasons.find((season) => season.ID === parseInt(e.target.value));
    this.setState({ selectedSeason });
    this.getGames(selectedSeason);
  };

  handleHeaderClick = (e) => {
    let [regularSortedStats, regularSortedHeader, regularSortMultiplier, regularIsAscending] = sortStatsTable(
      e,
      this.state.regularSeasonsStats,
      this.state.regularSortMultiplier,
      this.state.regularSortedHeader
    );
    updateQuery({ sortRegular: regularSortedHeader, ascRegular: regularIsAscending });
    this.setState({
      seasonsRegularStats: regularSortedStats,
      regularSortMultiplier,
      regularSortedHeader,
      regularIsAscending,
    });
  };

  handlePlayoffHeaderClick = (e) => {
    let [playoffSortedStats, playoffSortedHeader, playoffSortMultiplier, playoffIsAscending] = sortStatsTable(
      e,
      this.state.playoffSeasonsStats,
      this.state.playoffSortMultiplier,
      this.state.playoffSortedHeader
    );
    updateQuery({ sortPlayoffs: playoffSortedHeader, ascPlayoffs: playoffIsAscending });
    this.setState({
      seasonsPlayoffStats: playoffSortedStats,
      playoffSortMultiplier,
      playoffSortedHeader,
      playoffIsAscending,
    });
  };

  handleTotals = () => {
    this.setState({
      showTotals: !this.state.showTotals}, () => this.getPlayerStats()
    );
  };

  render = () => {
    const player = this.state.player;
    let { regSeasonGames, playoffGames } = this.state;

    return (
      <div>
        <h1 className="text-center">
          {player.FIRST_NAME} {player.LAST_NAME} #{player.JERSEY_NUMBER}
        </h1>
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "0.5rem"}}>
          <button className="totals-button" onClick={this.handleTotals}>
            {this.state.showTotals ? " Average Stats >>" : "Total Stats >>"}
          </button>
        </div>
        <Table
          title={"Regular season"}
          showSeason={true}
          stringInsteadOfSeason={"Total"}
          showTeam={true}
          stringInsteadOfTeam={"-"}
          showGamesPlayed={true}
          shouldRoundStats={!this.state.showTotals}
          rowsInfo={this.state.regularSeasonsStats}
          showRowsTotal={this.state.regularSeasonsStats.length > 0}
          rowsTotal={this.state.regularCareerStats}
          sortedHeader={this.state.regularSortedHeader}
          isAscending={this.state.regularIsAscending}
          handleHeaderClick={(e) => this.handleHeaderClick(e)}
        />
        <Table
          title={"Playoffs"}
          showSeason={true}
          stringInsteadOfSeason={"Total"}
          showTeam={true}
          stringInsteadOfTeam={"-"}
          showGamesPlayed={true}
          shouldRoundStats={!this.state.showTotals}
          rowsInfo={this.state.playoffSeasonsStats}
          showRowsTotal={this.state.playoffSeasonsStats.length > 0}
          rowsTotal={this.state.playoffCareerStats}
          sortedHeader={this.state.playoffSortedHeader}
          isAscending={this.state.playoffIsAscending}
          handleHeaderClick={(e) => this.handlePlayoffHeaderClick(e)}
        />

        {(
          <div className="container-season">
            <h1>Season:</h1>
            <SeasonSelect
              seasons={this.state.seasons}
              selectedSeason={this.state.selectedSeason}
              handleSeasonSelect={this.handleSeasonSelect}
            />
          </div>
        )}
        {regSeasonGames.length > 0 && (
          <Table
            title={"Regular season games"}
            showMatchup={true}
            showDate={true}
            rowsInfo={this.state.regSeasonGames}
            showRowsTotal={false}
            handleHeaderClick={() => {
              /* do nothing */
            }}
          />
        )}

        {playoffGames.length > 0 && (
          <Table
            title={"Playoff games"}
            showMatchup={true}
            showDate={true}
            rowsInfo={this.state.playoffGames}
            handleHeaderClick={() => {
              /* do nothing */
            }}
          />
        )}
      </div>
    );
  };
}

export default PlayerProfile;
