import React from "react";
import { formatVotesPercentage } from "../../constants/utils";
/**
 * Functional component that returns row in a voting results table based on the given parameters.
 *
 * @param props Object containing all the parameters
 * @param props.index integer indicating index/rank of given vote
 * @param props.votingResult object containing player and team information (keys: player {FIRST_NAME, LAST_NAME}, team {ID, NAME})
 * @param props.isVoted boolean to check if player is already voted for All Star
 * @param props.isBlocked boolean to limit number of players team captains can select for specific team
 * @param props.handleVote function to vote or remove All Star vote for given player
 * @param props.activeImgSrc image for active/voted All Star player
 * @param props.inactiveImgSrc image for inactive/unvoted All Star player
 * @param props.showVotes boolean to indicate if column with number of votes is visible
 *
 * @returns voting results table row
 */

const VotingResultsRow = (props) => {
  let {
    index,
    votingResult,
    isVoted,
    isBlocked,
    handleVote,
    activeImgSrc,
    inactiveImgSrc,
    showVotes,
  } = props;
  const {
    VOTEDPLAYER: player,
    VOTEDTEAM: team,
    REPLACINGPLAYER: replacingPlayer,
    REPLACINGTEAM: replacingTeam,
    TOTALVOTES: totalVotes,
    POSSIBLEVOTES: possibleVotes,
  } = votingResult;
  let playerName = player.FIRSTNAME + " " + player.LASTNAME;
  let teamName = team.NAME;

  if (replacingPlayer !== null) {
    playerName =
      replacingPlayer.FIRSTNAME +
      " " +
      replacingPlayer.LASTNAME +
      " | Replacing ** " +
      playerName +
      " **";
    teamName = replacingTeam.NAME;
  }

  if (!player)
    return (
      <tr>
        <td colSpan={5}></td>
      </tr>
    );

  return (
    <tr>
      <td>{index}.</td>
      <td>
        <button
          onClick={(e) => {
            e.preventDefault();
            (!isBlocked || isVoted) && handleVote(player, !isVoted);
          }}
          className={`all-star-button${
            isBlocked && !isVoted ? "-blocked" : ""
          }`}
        >
          {isVoted ? (
            <img src={activeImgSrc} style={{ maxWidth: "3rem" }} />
          ) : (
            <img src={inactiveImgSrc} style={{ maxWidth: "3rem" }} />
          )}
        </button>
      </td>
      <td className="sticky">
        <a>{playerName}</a>
      </td>
      <td>
        <a>{teamName}</a>
      </td>
      {showVotes && <td>{formatVotesPercentage(totalVotes, possibleVotes)}{"%"}{` (${totalVotes}/${possibleVotes})`}</td>}
    </tr>
  );
};

export default VotingResultsRow;
