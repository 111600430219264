import React from "react";
import AutocompleteSearch from "../utils/AutocompleteSearch";
import Dropdown from "../utils/Dropdown";


const Bar = (props) => {
  let {players, teams} = props

  const items = teams.map(team => {
    const item = {}
    item.href = "/teams/" + team.ID;
    item.text = team.NAME
    return item
  })

  return (
    <div className="container-navbar bar">
      <ul>
        <li>
          <a href="https://www.tiny.cc/FERBasket_FIBA" target="_blank" rel="noopener noreferrer">FIBA</a>
        </li>
        {/* <li>
          <a href="/">Home</a>
        </li> */}
        <li>
          <a href="/statistics">Statistics</a>
        </li>
        <li>
          <a href="/topPerformances">Top Performances</a>
        </li>
      </ul>
      <ul>
        <li>
          <a href="/playoff">Playoffs</a>
        </li>
        <li>
          <Dropdown text="Teams" items={items} openOnHover={true} />
        </li>
        <li>
          <AutocompleteSearch suggestions={players} placeholder="Find player..." />
        </li>
      </ul>
    </div>
  );
};

export default Bar;
