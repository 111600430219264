import React from "react";
import { STATS_TABLE_HEADERS, STATS_TABLE_HEADERS_WIDTH } from "../../constants/constants";
import Row from "../TableRows/Row";
import ARROW_UP from "../../assets/images/arrow_up_black.png";
import ARROW_DOWN from "../../assets/images/arrow_down_black.png";

// const ARROW_UP = "/images/arrow_up_black.png";
// const ARROW_DOWN = "/images/arrow_down_black.png";

/**
 * Functional component that returns a table based on the given parameters.
 * showSomething parameters indicate which header will be displaying in the table. stringInsteadOfSomething
 * parameters indicate values that should be written in a row that shows the table's totals.
 *
 * @param props Object containing all the parameters
 * @param props.title table title
 * @param props.hasPagination boolean indicating weather the table is paginated
 * @param props.handlePageChange function called when new page is clicked
 * @param props.elementsPerPage number of elements per page if the table is paginated
 * @param props.currentPage current page number if the table is paginated
 * @param props.showJerseyNumber boolean indicating display of player's jersey number
 * @param props.stringInsteadOfJerseyNumber string which when defined replaces player's jersey number
 * @param props.showSeason boolean indicating display of season
 * @param props.stringInsteadOfSeason string which when defined replaces season
 * @param props.showPlayerName boolean indicating display of player's name
 * @param props.stringInsteadOfPlayerName string which when defined replaces player's name
 * @param props.showTeam boolean indicating display of team's name
 * @param props.stringInsteadOfTeam string which when defined replaces team's name
 * @param props.showMatchup boolean indicating display of players's matchup
 * @param props.showDate boolean indicating display of date
 * @param props.stringInsteadOfDate string which when defined replaces date
 * @param props.showGamesPlayed boolean indicating display of games played
 * @param props.shouldRoundStats boolean indicating if the stats should be rounded
 * @param props.rowsInfo array of objects containing row information (keys: season, player, isStarter, team, stat)
 * @param props.showRowsTotal boolean indicating if the table contains a row showing totals
 * @param props.rowsTotal object containing total stats
 * @param props.sortedHeader string (table header) indicating by which value the the table is sorted by
 * @param props.isAscending boolean indicating if the sorting is in ascending order
 * @param props.handleHeaderClick function called when header is clicked
 *
 * @returns table
 */
const Table = (props) => {
  let {
    title,
    hasPagination,
    handlePageChange,
    elementsPerPage,
    currentPage,
    showJerseyNumber,
    stringInsteadOfJerseyNumber,
    showSeason,
    stringInsteadOfSeason,
    showPlayerName,
    stringInsteadOfPlayerName,
    showTeam,
    stringInsteadOfTeam,
    showMatchup,
    showDate,
    stringInsteadOfDate,
    showGamesPlayed,
    showPersonalFauls,
    showPlusMinus,
    shouldRoundStats,
    rowsInfo,
    showRowsTotal,
    rowsTotal,
    sortedHeader,
    isAscending,
    handleHeaderClick,
  } = props;

  if (!rowsInfo) {
    return null;
  }

  const isHeaderSortIndex = (header) => {
    return hasPagination && header === "#";
  };

  let tableHeaders = STATS_TABLE_HEADERS.map((header, i) => {
    if (
      (!showJerseyNumber && !hasPagination && header === "#") ||
      (!showSeason && header === "Season") ||
      (!showPlayerName && header === "Player") ||
      (!showTeam && header === "Team") ||
      (!showMatchup && header === "Matchup") || 
      (!showDate && header === "Date") || 
      (!showGamesPlayed && header === "GP") ||
      (!showPersonalFauls && header === "PF") ||
      (!showPlusMinus && header === "+/-")
    ) {
      return null;
    } else {
      let arrow = header === sortedHeader ? (isAscending ? ARROW_UP : ARROW_DOWN) : null;
      return (
        <th
          key={"header-" + i}
          style={{
            minWidth: STATS_TABLE_HEADERS_WIDTH[i] + "rem",
            zIndex: (header === "Player" ? 1 : 0),
          }}
          onClick={(e) => (isHeaderSortIndex(header) ? {} : handleHeaderClick(e))}
          value={header}
          className={"hover-select-pointer" + (header === "Player" ? " sticky" : "")}
        >
          {header}
          {!isHeaderSortIndex(header) && arrow && (
            <img className="header-sort-arrow" value={header} src={arrow} alt="" />
          )}
        </th>
      );
    }
  });

  let lastPage = Math.max(Math.ceil(rowsInfo.length / elementsPerPage), 1);
  if (currentPage && lastPage && currentPage > lastPage) {
    currentPage = lastPage;
  }
  if (currentPage && currentPage < 1) {
    currentPage = 1;
  }
  let rowsInfoPaginated = hasPagination
    ? rowsInfo.slice(elementsPerPage * (currentPage - 1), elementsPerPage * currentPage)
    : rowsInfo;

  const colSpan = tableHeaders.filter((header) => header !== null).length;

  return (
    <>
      <div className="container-table">
        <h2 className="text-left sticky">{title}</h2>
        <table>
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
          <tbody>
            {rowsInfoPaginated.length === 0 && (
              <tr>
                <td colSpan={colSpan}>No data available</td>
              </tr>
            )}
            {rowsInfoPaginated.map((rowInfo, i) => {
              let index = hasPagination ? elementsPerPage * (currentPage - 1) + 1 + i : 0;
              let rowIndex =
                (isAscending && sortedHeader !== "Player" && sortedHeader !== "Team") ||
                (!isAscending && (sortedHeader === "Player" || sortedHeader === "Team"))
                  ? rowsInfo.length - index
                  : index;
              return (
                <Row
                  key={i}
                  rowKey={"row-" + i}
                  hasPagination={hasPagination}
                  index={rowIndex}
                  showJerseyNumber={showJerseyNumber}
                  showSeason={showSeason}
                  showPlayerName={showPlayerName}
                  showTeam={showTeam}
                  showMatchup={showMatchup}
                  showDate={showDate}
                  showGamesPlayed={showGamesPlayed}
                  gamesPlayed={rowInfo.gamesPlayed}
                  showPersonalFauls={showPersonalFauls}
                  showPlusMinus={showPlusMinus}
                  shouldRoundStats={shouldRoundStats}
                  season={rowInfo.season}
                  player={rowInfo.player}
                  isStarter={rowInfo.isStarter}
                  team={rowInfo.team}
                  game={rowInfo.game}
                  stats={rowInfo.stat}
                />
              );
            })}
            {showRowsTotal && (
              <Row
                rowKey={"row-totals"}
                stringInsteadOfJerseyNumber={stringInsteadOfJerseyNumber}
                stringInsteadOfSeason={stringInsteadOfSeason}
                stringInsteadOfPlayerName={stringInsteadOfPlayerName}
                stringInsteadOfTeam={stringInsteadOfTeam}
                stringInsteadOfDate={stringInsteadOfDate}
                showGamesPlayed={showGamesPlayed}
                showPersonalFauls={showPersonalFauls}
                showPlusMinus={showPlusMinus}
                gamesPlayed={rowsTotal.gamesPlayed}
                shouldRoundStats={shouldRoundStats}
                stats={rowsTotal.stat}
              />
            )}
          </tbody>
        </table>
      </div>
      {hasPagination && (
        <div className="pagination">
          <p onClick={() => handlePageChange(1)}>&laquo;</p>
          <p onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}>&lt;</p>
          <p className="active">
            {currentPage}/{lastPage}
          </p>
          <p onClick={() => handlePageChange(Math.min(currentPage + 1, lastPage))}>&gt;</p>
          <p onClick={() => handlePageChange(lastPage)}>&raquo;</p>
        </div>
      )}
    </>
  );
};

export default Table;
