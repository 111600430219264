import React, { Component } from "react";
import axiosInstance from "../../api/AxiosInstance.jsx";
import {
  VOTING_CONTEST_ID_3PT,
  VOTING_CONTEST_ID_DUNK,
} from "../../constants/constants";
import { getQueryParams, updateQuery } from "../../constants/utils";

class VotingLogin extends Component {
  state = {
    voter: {},
    password: "",
    firstName: "",
    lastName: "",
    isChecked3ptContest: false,
    isCheckedDunkContest: false,
  };

  componentDidMount() {
    const queryParams = getQueryParams();
    const password = queryParams.get("teamPassword") || "";
    this.setState({ password });
  }

  onChangePassword = (e) => {
    e.preventDefault();
    const password = e.target.value;
    this.setState({ password });
  };

  onChangeFName = (e) => {
    e.preventDefault();
    const firstName = e.target.value;
    this.setState({ firstName });
  };

  onChangeLName = (e) => {
    e.preventDefault();
    const lastName = e.target.value;
    this.setState({ lastName });
  };

  onChange3ptContest = (e) => {
    const isChecked3ptContest = !this.state.isChecked3ptContest;
    this.setState({ isChecked3ptContest });
  };

  onChangeDunkContest = (e) => {
    const isCheckedDunkContest = !this.state.isCheckedDunkContest;
    this.setState({ isCheckedDunkContest });
  };

  redirect = async (e) => {
    let { firstName, lastName, password } = this.state;

    let queryParams = {};
    const isFilled = firstName !== "" && lastName !== "" && password !== "";
    const voterId =
      password +
      "-" +
      firstName.charAt(0).toUpperCase() +
      lastName.charAt(0).toUpperCase() +
      lastName.slice(1).toLowerCase();

    if (!isFilled) return;

    queryParams["voterId"] = voterId;

    const { isChecked3ptContest, isCheckedDunkContest } = this.state;
    if (isChecked3ptContest) {
      await this.saveContest(voterId, VOTING_CONTEST_ID_3PT);
    }
    if (isCheckedDunkContest) {
      await this.saveContest(voterId, VOTING_CONTEST_ID_DUNK);
    }

    updateQuery(queryParams);
    window.location.reload();
  };

  getVoter = (voterId) => {
    return axiosInstance.get(`/voters/${voterId}`).then((result) => {
      const voter = result.data;
      this.setState({ voter });
    });
  };

  saveContest = async (voterId, contestId) => {
    await this.getVoter(voterId);
    const { voter } = this.state;
    const votingResult = {
      voterId: voterId,
      votedPlayerId: voter.PLAYERID,
      votedTeamId: voter.TEAMID,
      contestId: contestId,
    };

    return axiosInstance.post("/votes", votingResult);
  };

  render = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "125px",
          display: "block",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <label style={{ textAlign: "center", fontSize: "20px" }}>
            Unesite lozinku ekipe:{" "}
            <input
              style={{ padding: "5px", width: "100px", fontSize: "15px" }}
              type="text"
              value={this.state.password}
              onChange={this.onChangePassword}
            />
          </label>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <label
            style={{ textAlign: "center", marginTop: "10px", fontSize: "20px" }}
          >
            Unesite svoje ime:{" "}
            <input
              style={{ padding: "5px", width: "150px", fontSize: "15px" }}
              type="text"
              value={this.state.firstName}
              onChange={this.onChangeFName}
            />
          </label>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <label
            style={{ textAlign: "center", marginTop: "10px", fontSize: "20px" }}
          >
            Unesite svoje prezime:{" "}
            <input
              style={{ padding: "5px", width: "150px", fontSize: "15px" }}
              type="text"
              value={this.state.lastName}
              onChange={this.onChangeLName}
            />
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <label
            style={{ textAlign: "center", marginTop: "10px", fontSize: "20px" }}
          >
            Prijava za 3pt Contest{" "}
            <input
              type="checkbox"
              checked={this.state.isChecked3ptContest}
              style={{ height: "20px", width: "20px", marginLeft: "10px" }}
              onChange={this.onChange3ptContest}
            />
          </label>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <label
            style={{ textAlign: "center", marginTop: "10px", fontSize: "20px" }}
          >
            Prijava za Dunk Contest{" "}
            <input
              type="checkbox"
              checked={this.state.isCheckedDunkContest}
              style={{ height: "20px", width: "20px", marginLeft: "10px" }}
              onChange={this.onChangeDunkContest}
            />
          </label>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "50px",
          }}
        >
          <button
            className="voting-button"
            style={{ textAlign: "center", marginTop: "10px" }}
            onClick={this.redirect}
          >
            Idi na glasanje {">>"}
          </button>
        </div>
      </div>
    );
  };
}

export default VotingLogin;
